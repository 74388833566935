<template>
	<div id="pageTop">
		<div class="row">
			<div class="col-12 col-lg-8 mb-4">
				<BookingForm />
			</div>
			<div class="col-12 col-lg-4 d-none d-lg-block">
				<BookingSummary />
			</div>
		</div>
	</div>
</template>

<script>
import { formatCurrency } from "../../utils";
import BookingForm from "./BookingForm.vue"
import BookingSummary from "./BookingSummary.vue";
	export default {
		name: "new-booking",
		components: {
			BookingForm, 
			BookingSummary, 
		},
		computed:{
			totalTravellers(){
				return this.$store.state.totalTravellers;
			},
			totalTripCost(){
				return this.$store.state.totalTripCost;
			},
			totalTripDeposit(){
				return this.$store.state.totalTripDeposit;
			},
		},
		data(){
			return{
				formatCurrency,
				isBusy: false,
			}
		},
		methods:{

		},
		mounted(){
        }

	}
</script>
