<template>
	<div class="my-3">
		<div class="form-floating">
			<input :type="type" class="form-control" :class="{ 'is-invalid': error }" :id="inputId" :disabled="disabled" :placeholder="label" 
			@input="$emit('update:modelValue', $event.target?.value); "
			@focus="$emit('clearFormError')"/>
			<label :for="inputId">{{label}}<span class="text-danger" v-if="required">*</span></label>
		</div>
		<div class="text-danger small" v-if="error" :aria-describedby="inputId">
			<em>{{ error }}</em>
		</div>
	</div>
</template>
<script>
import { defineComponent } from "vue";
import { v4 } from 'uuid';
export default defineComponent({
	name: 'BaseInput',
	emits: ["update:modelValue", "clearFormError", "onChange"],
	props: {
		modelValue: {
			type: [String, Number],
			default: "",
		},
		label: {
			type: String,
			default: ""
		},
		type:{
			type: String,
			default: "text"
		},
		required: {
			type: Boolean,
			default: false,
		},
		disabled:{
			type:Boolean,
			default: false
		},
		error: {
			type: String,
			default: "",
		},
	},
	
	data() {
		return {
			inputId: `input-${v4()}`,
		};
	}
})
</script>