<template>
<div class="p-1 p-md-4 mb-5" id="pageTop">
    <BasePageTitle
      hideBackButton
      title="Rotary WIndies Tour of Australia"
      subTitle="May 21 to June 02, 2023, Melbourne, Australia."
    />
    <div class="row">
      <div class="col-lg-7">
        <div class="row">
			<div class="mb-3.">
				<div class="alert alert-info">
				Your request has been submitted. You will receive an email once your request has been reviewed. We will contact you if we require further information.
				</div>
			</div>
			<div class="my-1">
				<BaseButton label="Submit another request" fullWidth size="lg" @click="btnContinueClick" />
			</div>
			<div class="my-1">
				<BaseButtonOutlined label="Exit" fullWidth size="lg" @click="btnExitClick" :busy="appBusy" />
			</div>
		</div>
	  </div>
	</div>
</div>
</template>
<script>
import BasePageTitle from '@/components/common/BasePageTitle.vue';
import BaseButton from '@/components/common/BaseButton.vue';
import BaseButtonOutlined from '@/components/common/BaseButtonOutlined.vue';
export default {
	name: "RIConventionSuccess",
	components: {
		BasePageTitle,
		BaseButton,
		BaseButtonOutlined,
	},
	data(){
		return {
			appBusy: false,
		}
	},
	methods: {
		btnContinueClick() {
			this.$router.push("/book/ri-convention");
		},
		btnExitClick() {
			this.appBusy = true;
			window.location.href = "https://socaislands.com/riconvention"
		},
	},
}
</script>