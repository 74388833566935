<template>
	<ul class="list-group">
		<li class="list-group-item active bg-dark border-dark">
			<h5 class="my-1"><i class="fas fa-users"></i> Traveller details</h5>
		</li>
		<li class="list-group-item">
			<p class="">Traveler details must correspond with their passport information.</p>
			<div class="row" v-for="index in totalTravellers" :key="index">
				<div class="col-12">
					<div class="alert alert-secondary my-3">
						{{
							index - 1 == 0
							? "Lead traveller details"
							: `Traveller number ${index} details`
						}}
					</div>
					<div class="row">
						<div class="col-12 mb-3">
							<label class="small text-muted">First name*</label>
							<input v-model.trim="customers.fname[index - 1]" class="form-control"
								@input="formValueChanged" />
						</div>
						<div class="col-12 mb-3">
							<label class="small text-muted">Last name*</label>
							<input v-model.trim="customers.lname[index - 1]" class="form-control"
								@input="formValueChanged" />
						</div>
						<div class="col-12 mb-3">
							<label class="small text-muted">Email address*</label>
							<input v-model.trim="customers.email[index - 1]" class="form-control"
								@input="formValueChanged" />
						</div>
						<div class="col-12 mb-3">
							<label class="small text-muted">Phone number*</label>
							<input v-model.number="customers.phone[index - 1]" class="form-control"
								@input="formValueChanged" />
						</div>
						<div class="col-12 mb-3">
							<label class="small text-muted">Gender*</label>
							<select v-model="customers.gender[index - 1]" class="form-select" @change="formValueChanged">
								<option disabled selected></option>
								<option value="female">Female</option>
								<option value="male">Male</option>
								<option value="non-binary">Non-binary</option>
							</select>
						</div>
						<div class="col-12 mb-3">
							<label class="small text-muted">T-shirt size*</label>
							<select v-model="customers.tshirt_size[index - 1]" class="form-select"
								@change="formValueChanged">
								<option disabled selected></option>
								<option value="female-small">Female Small</option>
								<option value="female-medium">Female Medium</option>
								<option value="female-large">Female Large</option>
								<option value="female-x-large">Female X Large</option>
								<option value="female-2x-large">Female 2X Large</option>
								<option value="female-3x-large">Female 3X Large</option>
								<option disabled>---</option>
								<option value="male-small">Male Small</option>
								<option value="male-medium">Male Medium</option>
								<option value="male-large">Male Large</option>
								<option value="male-x-large">Male X Large</option>
								<option value="male-2x-large">Male 2X Large</option>
								<option value="male-3x-large">Male 3X Large</option>
							</select>
						</div>
						<div class="col-12 mb-3">
							<label class="small text-muted">Notes (optional)</label>
							<textarea v-model.trim="customers.notes[index - 1]" rows="3" max-rows="3" wrap="hard"
								class="form-control" style="resize: none" @input="formValueChanged" />
						</div>
					</div>
				</div>
			</div>
		</li>
	</ul>
</template>

<script>
export default {
	name: "traveller-details",
	components: {},
	computed: {
		totalTravellers() {
			return this.$store.state.totalTravellers;
		},
		validateForm() {
			const { fname, lname, email, phone, gender, tshirt_size } = this.customers;
			for (let i = 0; i < fname.length; i++) {
				if (!fname[i] || fname[i]?.length < 2) {
					return false;
				}
				if (!lname[i] || lname[i]?.length < 2) {
					return false;
				}
				if (!email[i]) {
					return false;
				}
				if (!phone[i]) {
					return false;
				}
				if (!gender[i]) {
					return false;
				}
				if (!tshirt_size[i]) {
					return false;
				}
			}
			return true;
		},
	},
	data() {
		return {
			isBusy: false,
			customers: {
				fname: [],
				lname: [],
				email: [],
				phone: [],
				gender: [],
				tshirt_size: [],
				notes: [],
			},
		};
	},
	methods: {
		formValueChanged() {
			if (this.validateForm) {
				this.$store.commit("setTravellers", this.customers);
			} else {
				this.$store.commit("setTravellers", {});
			}
		},
	},
	mounted() { },
};
</script>
