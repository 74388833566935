<template>
	<div class="my-3">
		<div class="form-floating">
			<select class="form-select" :class="{ 'is-invalid': error }" 
			:id="inputId"
			:value="modelValue"
			v-bind="{
				...$attrs,
				onChange: ($event) => {$emit('update:modelValue', $event.target.value); $emit('clearFormError')}
			}">
				<option disabled selected></option>
				<template v-for="option in options" :key="option.value">
					<option
						:value="option?.value ?? option"
						:selected="option?.value ?? option === modelValue"
					>
						{{option?.label || option}}
					</option>
				</template>
			</select>
			<label :for="inputId">{{label}}<span class="text-danger" v-if="required">*</span></label>
		</div>
		<div class="text-danger small" v-if="error" :aria-describedby="inputId">
			<em>{{ error }}</em> 
		</div>
	</div>
</template>
<script>
import { v4 } from 'uuid';
export default {
	name: 'BaseSelect',
	emits: ["update:modelValue", "clearFormError"],
	props: {
		modelValue: {
			type: [String, Number, Boolean, Object, Array],
			default: "",
		},
		label: {
			type: String,
			default: ""
		},
		type:{
			type: String,
			default: "text"
		},
		rows:{
			type: String,
			default: "100"
		},
		options:{
			type: Array,
			default: () => []
		},
		error: {
			type: String,
			default: "",
		},
		required: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			inputId: `input-${v4()}`,
		};
	}
}
</script>