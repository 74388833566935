<template>
<div class="p-1 p-md-4 mb-5" id="pageTop">
    <BasePageTitle
      hideBackButton
      title="Rotary WIndies Tour of Australia"
      subTitle="May 21 to June 02, 2023, Melbourne, Australia."
    />
    <div class="row">
      <div class="col-lg-7">
        <div class="row">
			<div class="mb-3. fs-4 fw-bold">
				<div class="alert. alert-info.">
				Compare Packages
				</div>
			</div>	
			<div class="mb-3">
				Included in every package are curated tour and fellowship activities, transfers and ground transportation, flexible payment plan, Rotary WIndies gift bag, and more. 
			</div>	
			<div class="alert alert-info">
				The "Hotel" column in the table below refers to accommodations from May 26 to June 02, 2023. All other accommodations for the scheduled stops are included in the packages. The packages without "Hotel" are for those who have already booked their own accommodations during the RI Convention.
			</div>		
			<div class="mb-4 table-responsive">
				<table class="table">
					<thead class="small">
						<tr>
							<th>Package</th>
							<th class="text-center">Flights</th>
							<th class="text-center">Hotel</th>
							<th class="text-center text-truncate">Meet in</th>
						</tr>
					</thead>
					<tbody>
						<template v-for="feature in packageFeatures" :key="feature">
							<tr>
								<td>{{feature?.name}}</td>
								<td class="text-center">{{feature?.flights}}</td>
								<td class="text-center">{{feature?.hotel}}</td>
								<td class="text-center">{{feature?.meet_at}}</td>
							</tr>
						</template>
					</tbody>
				</table>
			</div>
			<div class="my-5">
				<BaseButton label="Book now" fullWidth size="lg" @click="btnContinueClick" />
			</div>
		</div>
	  </div>
	</div>
</div>
</template>
<script>
import BasePageTitle from '@/components/common/BasePageTitle.vue';
import BaseButton from '@/components/common/BaseButton.vue';
import BaseButtonOutlined from '@/components/common/BaseButtonOutlined.vue';
export default {
	name: "RIConventionSuccess",
	components: {
		BasePageTitle,
		BaseButton,
		BaseButtonOutlined,
	},
	data(){
		return {
			appBusy: false,
			packageFeatures:[
				{
					name: "Covered Stand",
					meet_at: "L.A.",
					flights: "Y",
					hotel: "Y",
				},
				{
					name: "Uncovered Stand",
					meet_at: "L.A.",
					flights: "Y",
					hotel: "N",
				},
				{
					name: "Sydney End",
					meet_at: "Sydney",
					flights: "N",
					hotel: "Y",
				},
				{
					name: "Sydney End - No Accommodation",
					meet_at: "Sydney",
					flights: "N",
					hotel: "N",
				}
			],
		}
	},
	methods: {
		btnContinueClick() {
			this.$router.push("/book/ri-convention");
		},
		btnExitClick() {
			this.appBusy = true;
			window.location.href = "https://socaislands.com/riconvention"
		},
	},
}
</script>