<template>
	<div class="p-md-4 mb-5" id="pageTop">
		<div class="row">
            <div class="col-12">
				<div class="row my-3">
					<div class="col-auto mt-3 h2 pointer" @click="$router.go(-1)">
						<i class="bi bi-chevron-left"></i>
					</div>
					<div class="col p-1 h4 my-2 mb1-3">
						<span class="fw-bold h2">JFK to GND ROUND TRIP</span>
						<br/><small class="fs-6"><a class="text-decoration-none" href="https://www.prestigeair.uk">powered by Prestige Air</a></small>
					</div>
				</div>
            </div>
		</div>
		<div class="row">
			<div class="col-lg-7">
				<div class="row">
					<div class="d-lg-none">
						<img class="img-fluid rounded" src="../assets/img/jfk-gnd-mobile-image.jpg" />
					</div>
					<div class="col-12 mt-3 fs-3 fw-bold">
						Before you book your Grenada Spicemas flight
					</div>
					<div class="col-12 my-2 alertl alertl-info">
						<p>
							<strong>Aircraft type.</strong> 
							<br/>The aircraft being used for this charter is a Boeing 737-200
						</p>
						<p>
							<strong>Charter operator.</strong> 
							<br/>Although this charter is being promoted by Soca Islands and Lavish the Band, this charter flight is solely managed and operated by <strong>Prestige Air</strong> - <a class="text-decoration-none" href="https://www.prestigeair.uk">www.prestigeair.uk <span class="small"><i class="bi bi-box-arrow-up-right small"></i></span></a>.
						</p>
						<p>
							<strong>Time and gate info.</strong> 
							<br/>This is going to be an A.M. flight. The exact departure time and departure gate number will be announced closer to date of departure.
							It is very important to note, that as a chartered flight, we shall be leaving promptly at the time assigned to us. We therefore advise that you arrive at least THREE (3) HOURS before departure.
						</p>
						<p>
							<strong>Baggage allowance.</strong> 
							<br/>For this charter you are allowed one (1) personal item, one (1) carry-one bag and one (1) checked bag not exceeding 23KG. <strong>NO EXCEPTIONS.</strong>
						</p>
						<p>
							<strong>Refreshments.</strong> 
							<br/>A light snack and refreshment will be served on the aircraft. <!-- If you suffer from any allergies, please send an <a class="text-decoration-none" href="mailto: explore@socaislands.com?subject=Allergy information - Spicemas 2022 charter flight">email to explore@socaislands.com</a>, or send a <a class="text-decoration-none" href="https://wa.me/18683882937">WhatsApp message to +18683882937</a>. -->
						</p>
						<p>
							<strong>Rebate offer.</strong> 
							<br/>We have a special offer for <strong>Soca Islands customers and Lavish masqueraders</strong>. After booking your flight, please send <a class="text-decoration-none" href="mailto: explore@socaislands.com?subject=Rebate offer - Spicemas 2022 charter flight">email to explore@socaislands.com</a>, or send a <a class="text-decoration-none" href="https://wa.me/18683882937">WhatsApp message to +18683882937</a>, with a copy of your Lavish or Soca Islands receipt to get $50 back instantly.
						</p>
						<p>
							<strong>Terms and conditions.</strong> 
							<br/>Please be sure to review our <a class="text-decoration-none" href="" target="_terms">terms and conditions <span class="small"><i class="bi bi-box-arrow-up-right small"></i></span></a>, <a class="text-decoration-none" href="https://socaislands.com/legal-stuff/#privacy-policy" target="_privacy">privacy policy <span class="small"><i class="bi bi-box-arrow-up-right small"></i></span></a>, <a class="text-decoration-none" href="https://travel.state.gov/content/passports/en/alertswarnings.html" target="_gta">Government Travel Advisory <span class="small"><i class="bi bi-box-arrow-up-right small"></i></span></a> and <a href="https://www.puregrenada.com/travel-advisory/" class="text-decoration-none" target="_gta">Grenada's travel requirements  <span class="small"><i class="bi bi-box-arrow-up-right small"></i></span>.</a>
						</p>
						<p>
							<strong>Questions.</strong> 
							<br/>If you have any additional questions about this charter, please send an <a class="text-decoration-none" href="mailto: explore@socaislands.com?subject=Question about the Spicemas 2022 charter flight">email to explore@socaislands.com</a>, or send a <a class="text-decoration-none" href="https://wa.me/18683882937">WhatsApp message to +18683882937</a>.
						</p>
					</div>
					<div class="col-12 mt-3 fs-3 fw-bold">
						Flight details
					</div>
					<div class="col-12 my-2">
						<label class="small">
							<span class="text-danger">*</span>Flight departure date
						</label>
						<select class="form-select" v-model="flight.departure_date" >
							<option>Wednesday August 03rd 2022 - JFK</option>
						</select>
					</div>
					<div class="col-12 my-2">
						<label class="small">
							<span class="text-danger">*</span>Flight return date
						</label>
						<select class="form-select" v-model="flight.return_date" >
							<option>Wednesday August 10th 2022 - GND</option>
						</select>
					</div>
					<div class="col-12 my-2">
						<label class="small">
							<span class="text-danger">*</span>Flight option
						</label>
						<select class="form-select" v-model="flight.ticket">
							<option disabled selected></option>
							<option :value="{ name: 'ROUND TRIP ONLY', price: 768.30 }">ROUND TRIP ONLY - $800.00 USD</option>
							<option :value="{ name: 'ROUND TRIP PLUS AIRPORT TRANSFERS', price: 864.40 }">ROUND TRIP PLUS AIRPORT TRANSFERS - $900.00 USD</option>
							<option :value="{ name: 'ONE-WAY ONLY (from JFK)', price: 768.30 }">ONE-WAY ONLY (from JFK) - $800.00 USD</option>
							<option :value="{ name: 'ONE-WAY ONLY (from GND)', price: 768.30 }">ONE-WAY ONLY (from GND) - $800.00 USD</option>
							<!-- <option :value="{ name: 'TEST', price: 1 }">TEST - $1.00 USD</option> -->
						</select>
					</div>
					<div class="col-12 mt-5 fs-3 fw-bold">
						Who's travelling?
					</div>
					<div class="col-12 my-2">
						<label class="small">
							<span class="text-danger">*</span>First name (as shown on passport)
						</label>
						<input class="form-control" v-model.trim="passenger.fname" />
					</div>
					<div class="col-12 my-2">
						<label class="small">
							Middle name (optional, as shown on passport)
						</label>
						<input class="form-control" v-model.trim="passenger.mname" />
					</div>
					<div class="col-12 my-2">
						<label class="small">
							<span class="text-danger">*</span>Last name (as shown on passport)
						</label>
						<input class="form-control" v-model.trim="passenger.lname" />
					</div>
					<div class="col-12 my-2">
						<label class="small">
							<span class="text-danger">*</span>Email address
						</label>
						<input type="email" class="form-control" v-model.trim="passenger.email" />
					</div>
					<div class="col-12 my-2">
						<label class="small">
							<span class="text-danger">*</span>Mobile number
						</label>
						<input type="number" class="form-control" v-model.number="passenger.phone" />
						<div class="form-check mt-2">
							<input class="form-check-input" type="checkbox" :value="true" id="textMessages"  v-model="passenger.receive_text">
							<label class="form-check-label small" for="textMessages">
								Receive text alerts about this trip. Message and data rates may apply.
							</label>
						</div>
					</div>
					<div class="col-12 my-2">
						<label class="small">
							<span class="text-danger">*</span>Date of birth
						</label>
						<div class="row gx-2">
							<div class="col-4 my-2">
								<select class="form-select" v-model="passenger.dob.month" >
									<option disabled selected>Month</option>
									<option>January</option>
									<option>February</option>
									<option>March</option>
									<option>April</option>
									<option>May</option>
									<option>June</option>
									<option>July</option>
									<option>August</option>
									<option>September</option>
									<option>October</option>
									<option>November</option>
									<option>December</option>
								</select>
							</div>
							<div class="col-4 my-2">
								<select class="form-select" v-model="passenger.dob.day" >
									<option disabled selected>Day</option>
									<template v-for="index in 31" :key="index">
										<option>{{index}}</option>
									</template>
								</select>
							</div>
							<div class="col-4 my-2">
								<select class="form-select" v-model="passenger.dob.year" >
									<option disabled selected>Year</option>
									<template v-for="index in 60" :key="index">
										<option>{{2005 - index}}</option>
									</template>
								</select>
							</div>
						</div>
					</div>
					<div class="col-12 my-2">
						<label class="small">
							<span class="text-danger">*</span>Gender
						</label>
						<select class="form-select" v-model="passenger.gender" >
							<option disabled selected></option>
							<option>Female</option>
							<option>Male</option>
							<option>Non-binary</option>
						</select>
					</div>
					<div class="col-12 my-2">
						<label class="small">
							<span class="text-danger">*</span>Passport
						</label>
						<select class="form-select" v-model="passenger.passport.country" >
							<option>United States of America</option>
							<option>Other</option>
						</select>
					</div>
					<div class="col-12 my-2">
						<label class="small">
							<span class="text-danger">*</span>Passport number
						</label>
						<input class="form-control" v-model.trim="passenger.passport.number" />
					</div>
					<div class="col-12 my-2">
						<label class="small">
							<span class="text-danger">*</span>Passport issue date
						</label>
						<input type="date" class="form-control" v-model="passenger.passport.issue_date" />
					</div>
					<div class="col-12 my-2">
						<label class="small">
							<span class="text-danger">*</span>Passport expiry date
						</label>
						<input type="date" class="form-control" v-model="passenger.passport.expiry_date" />
					</div>
					<div class="col-12 mt-5 fs-3 fw-bold">
						Emergency contact
					</div>
					<div class="col-12 my-2">
						<label class="small">
							<span class="text-danger">*</span>Emergency contact's full name
						</label>
						<input class="form-control" v-model.trim="passenger.emergency_contact.name" />
					</div>
					<div class="col-12 my-2">
						<label class="small">
							<span class="text-danger">*</span>Emergency contact's email address
						</label>
						<input type="email" class="form-control" v-model.trim="passenger.emergency_contact.email" />
					</div>
					<div class="col-12 my-2">
						<label class="small">
							<span class="text-danger">*</span>Emergency contact's mobile number
						</label>
						<input type="number" class="form-control" v-model.number="passenger.emergency_contact.phone" />
					</div>
					<div class="col-12 mt-5 fs-3 fw-bold">
						Review your trip and details
					</div>
					<div class="col-12 my-2 alert1 alert-info1 mb-5 d-grid small">
						<ol>
							<li>
								Review your trip details to make sure the dates are correct. The travel time will be sent to you at a later date.
							</li>
							<li>
								Check your spelling. Flight passenger names must match government-issued passport exactly.
							</li>
							<li>
								Review the terms of your booking:
								<div class="border border-secondary bg-white my-2 py-2 pe-3" style="height: 30vh; overflow-y: scroll;">
									<ul>
										<li>
											Fare rules for JFK to GND:
											<ul>
												<li>Choose your seat (first come, first serve)</li>
												<li>Bring a personal item</li>
												<li>Bring a carry-on bag</li>
												<li>Bring a checked bag not weighing more than 23kg</li>
												<li>Cancellations NOT allowed after June 15, 2022</li>
												<li>REFUNDS (with a $0 penalty) are allowed until May 31, 2022</li>
												<li>REFUNDS (with a $300 penalty) are allowed between May 31 and June 15, 2022</li>
												<li><strong>Absolutely NO REFUNDS for ANY reason will be allowed after June 15, 2022</strong></li>
												<li>Transfers NOT allowed after July 03, 2022</li>
											</ul>
										</li>
										<li>
											Fare rules for GND to JFK:
											<ul>
												<li>Choose your seat (first come, first serve)</li>
												<li>Bring a personal item</li>
												<li>Bring a carry-on bag</li>
												<li>Bring a checked bag not weighing more than 23kg</li>
												<li>Cancellations NOT allowed</li>
												<li>Changes NOT allowed</li>
											</ul>
										</li>
										<li><strong>Tickets are non-refundable after June 15, 2022 and non-transferable after July 03, 2022.</strong> Name changes are not allowed after July 03, 2022.</li>
										<li>Please read the <a class="text-decoration-none" target="_blank" href="https://socaislands.com/flights-fare-rules">complete penalty rules for changes and cancellations or charter contract <span class="small"><i class="bi bi-box-arrow-up-right small"></i></span></a>.</li>
										<li>Please read important information regarding <a href="https://socaislands.com/flights-warsaw"  class="text-decoration-none" target="_blank">charter flight liability limitations <span class="small"><i class="bi bi-box-arrow-up-right small"></i></span></a>.</li>
										<li>Federal and international law forbids the carriage of hazardous materials aboard the aircraft in your luggage or on your person.</li>
    								</ul>
								</div>
							</li>
						</ol>
						<p>
							By clicking on the button below, I acknowledge that I have reviewed the <a class="text-decoration-none" href="https://socaislands.com/legal-stuff/#privacy-policy" target="_privacy">Privacy Policy <i class="bi bi-box-arrow-up-right small"></i></a>, <a class="text-decoration-none" href="https://www.puregrenada.com/travel-advisory/" target="_gta">Grenada Tourism Authority <i class="bi bi-box-arrow-up-right small"></i></a> and <a class="text-decoration-none" href="https://travel.state.gov/content/passports/en/alertswarnings.html" target="_gtusa">Government Travel Advisory <i class="bi bi-box-arrow-up-right small"></i></a> and have reviewed and accept the above Rules & Restrictions and <a class="text-decoration-none" href="" target="_terms">Terms and Conditions <i class="bi bi-box-arrow-up-right small"></i></a>.
						</p>
						<button class="btn btn-lg btn-dark mt-4 mb-3" data-bs-toggle="modal" data-bs-target="#paymentModal" :disabled="!flight.ticket?.price" @click="btnContinueClick">
							Continue to pay ${{ amountDue }}.00 USD<i class="bi bi-chevron-right ms-2"></i>
						</button>
					</div>
				</div>
			</div>
			<div class="col-lg-5">
				<div class="sticky-top d-none d-lg-block">
					<img class="img-fluid rounded" src="../assets/img/jfk-gnd-poster.jpg" />
				</div>
			</div>
		</div>

		<!-- Modal -->
		<div class="modal fade" id="paymentModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="paymentModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-md modal-dialog-scrollable">
				<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title fs-2 fw-bold" >Complete your booking</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btnClosePaymentModal"></button>
				</div>
				<div class="modal-body">
					<div class="row gx-1">
						<div class="col-auto small text-success">
							<i class="bi bi-lock-fill me-2"></i>
						</div>
						<div class="col text-secondary small">
							We use secure transmission to protect your personal information.
						</div>
					</div>
					<div class="row my-3">
						<div class="col-12 fs-3 fw-bold">
							Billing information
						</div>
						<div class="col-12 small">
							<button class="btn btn-sm btn-link" @click="btnUseTravellerInfoClick">
								Use traveller information
							</button>
						</div>
						<div class="col-12 my-2">
							<label class="small">
								<span class="text-danger">*</span>Cardholder's full name
							</label>
							<input class="form-control" v-model="billing.name" />
						</div>
						<div class="col-12 my-2">
							<label class="small">
								<span class="text-danger">*</span>Cardholder's email address
							</label>
							<input type="email" class="form-control" v-model="billing.email" />
						</div>
						<div class="col-12 my-2">
							<label class="small">
								<span class="text-danger">*</span>Cardholder's mobile number
							</label>
							<input type="number" class="form-control" v-model="billing.phone" />
						</div>
						<div class="col-12 my-2">
							<PaymentMethod 
							ref="paymentMethodRef"
							:adHocPayment="true"
							:receiptEmail="billing?.email"
							:paymentDescriptor="`${passenger.fname?.toUpperCase()} ${passenger.lname?.toUpperCase()} - CHARTERED FLIGHT - SPICEMAS 2022 - ${flight.ticket?.name}`"
							:key="paymentMethodComponentKey"
							/>
						</div>
						<div class="col-12 my-2 d-grid">
							<button class="btn btn-lg btn-dark mt-4 mb-3" @click="btnPayClick" :disabled="isBusy">
								<div
									class="spinner-border"
									role="status"
									v-if="isBusy"
								>
									<span class="visually-hidden">Loading...</span>
								</div>
								<span v-else>
									Complete booking <i class="bi bi-chevron-right ms-2"></i>
								</span>
							</button>
						</div>
					</div>
				</div>
			</div>
			</div>
		</div>

		<!-- Modal -->
		<a id="btnOpenSuccessModal" data-bs-toggle="modal" data-bs-target="#successModal"></a>
		<div class="modal fade" id="successModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="successModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-md modal-dialog-scrollable">
				<div class="modal-content">
				<div class="modal-header">
					<!-- <h5 class="modal-title fs-2 fw-bold" ></h5> -->
					<button class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close" id="btnCloseSuccessModal"></button>
				</div>
				<div class="modal-body">
					<div class="row">
						<div class="col-12 fs-1 fw-bold text-center">
							<i class="bi bi-check-circle text-primary"></i>
							<br/>Confirmed: You just booked a flight
						</div>
						<div class="col-12 my-2">
							Thank you for your purchase! Your flight booking is now complete. Your order reference is <span class="text-primary">{{order_ref}}</span>.
						</div>
						<div class="col-12 my-2">
							Unlock your $50 rebate by submitting your Lavish the Band or Soca Islands receipt via <a class="text-decoration-none" href="mailto: explore@socaislands.com?subject=Rebate offer - Spicemas 2022 charter flight">email to explore@socaislands.com</a>, or send a <a class="text-decoration-none" href="https://wa.me/18683882937">WhatsApp message to +18683882937</a>.
						</div>
						<div class="col-12 my-2">
							This is going to be an A.M. flight. The exact departure time and departure gate number will be announced closer to date of departure. It is very important to note, that as a chartered flight, we shall be leaving promptly at the time assigned to us. We therefore advise that you arrive at least THREE (3) HOURS before departure. Also, 
							<ul>
								<li><strong>Tickets are non-refundable after June 15, 2022 and non-transferable after July 03, 2022.</strong> Name changes are not allowed after July 03, 2022.</li>
								<li>Please read the <a class="text-decoration-none" target="_blank" href="https://socaislands.com/flights-fare-rules">complete penalty rules for changes and cancellations or charter contract <span class="small"><i class="bi bi-box-arrow-up-right small"></i></span></a>.</li>
								<li>Please read important information regarding <a href="https://socaislands.com/flights-warsaw"  class="text-decoration-none" target="_blank">charter flight liability limitations <span class="small"><i class="bi bi-box-arrow-up-right small"></i></span></a>.</li>
								<li>Federal and international law forbids the carriage of hazardous materials aboard the aircraft in your luggage or on your person.</li>
							</ul>
						</div>
						<div class="col-12 my-2 d-grid">
							<button class="btn btn-lg btn-dark mt-4 mb-3" @click="btnFinishClick">
								Done <i class="bi bi-chevron-right ms-2"></i>
							</button>
						</div>
					</div>
				</div>
			</div>
			</div>
		</div>

	</div>
</template>

<script>
import { v4 as uuidv4  } from "uuid";
import { doc, setDoc } from "firebase/firestore"; 
import { db, collections } from "../firebaseConfig";
import PaymentMethod from '../components/common/PaymentMethod.vue';
export default {
	name: "BookFlight",
	components: { PaymentMethod },
	computed:{
		amountDue(){
			const { flight } = this;
			const amountDue = flight.ticket?.price ? ((flight.ticket?.price + .50)/0.961) : 0;  
			return amountDue;
		},
		isBusy(){
			return this.$store.state.isReservationFormBusy;
		}
	},
	data(){
		return{
			paymentMethodComponentKey: null,
			flight:{
				departure_date: "",
				return_date: "",
				ticket: "",
			},
			passenger:{
				fname: "",
				mname: "",
				lname: "",
				email: "",
				phone: "",
				receive_text: true,
				dob:{
					month: "",
					day: "",
					year: ""
				},
				gender: "",
				passport:{
					country: "",
					number: "",
					issue_date: "",
					expiry_date: "",
				},
				emergency_contact:{
					name: "",
					email: "",
					phone: "",
				},
				agree_terms: true,
			},
			billing:{
				name: "",
				email: "",
				phone: "",
			},
			ip_data: {},
			order_id: "",
			order_ref: "",
		}
	},
	methods:{
		btnFinishClick(){
			window.scrollTo(0, 0);
			this.flight = {
				departure_date: "",
				return_date: "",
				ticket: "",
			};
			this.passenger = {
				fname: "",
				mname: "",
				lname: "",
				email: "",
				phone: "",
				receive_text: true,
				dob:{
					month: "",
					day: "",
					year: ""
				},
				gender: "",
				passport:{
					country: "",
					number: "",
					issue_date: "",
					expiry_date: "",
				},
				emergency_contact:{
					name: "",
					email: "",
					phone: "",
				},
				agree_terms: true,
			};
			this.billing = {
				name: "",
				email: "",
				phone: "",
			};
			this.ip_data = {};
			this.order_id = "";
			this.order_ref = "";

			const btnCloseSuccessModal = document.getElementById("btnCloseSuccessModal");
			btnCloseSuccessModal.click();
		},
		async btnContinueClick(){
			const paymentAmount = this.flight.ticket?.price ?? 0;
			this.ip_data = await this.$store.dispatch("getIpAddress");
			this.order_id = uuidv4();
			this.order_ref = Date.now();
			this.$store.commit("setPaymentAmount", paymentAmount);
			this.$store.dispatch("getStripeAmount", { paymentAmount: paymentAmount });
		},
		async btnPayClick(){
			const paymentAmount = this.flight.ticket?.price ?? 0;
			const { response, error } = await this.$refs.paymentMethodRef.submitAdHocPayment({ orderId: this.order_id, paymentAmount, userEmail: this.passenger.email });
			if(response === true){
				const { flight, passenger, billing, ip_data, order_id, order_ref } = this;
				const order_date = new Date();
				try{
					await setDoc(doc(db, collections.FLIGHT_BOOKINGS, order_id), {
						order_ref,
						order_date,
						flight,
						passenger,
						billing,
						ip_data
					});
					const btnClosePaymentModal = document.getElementById("btnClosePaymentModal");
					const btnOpenSuccessModal = document.getElementById("btnOpenSuccessModal");
					btnClosePaymentModal.click();
					btnOpenSuccessModal.click();
				}catch(e){
					console.log(e);
				}finally{
					//...
				}
			}else if (error){
				console.log(error);
			}
		},
		btnUseTravellerInfoClick(){
			this.billing.name = this.passenger.fname + " " + this.passenger.lname;
			this.billing.email = this.passenger.email;
			this.billing.phone = this.passenger.phone;
		}
	},
	created(){
		
	},
	mounted(){
		this.$store.commit("setPageTitle", "Book Flight")
	}

}
</script>
<style>
.app-logo{
	width: 70px !important;
	height: auto;
}
.modal-header{
	border-bottom: 0 !important;
}
</style>
