<template>
	<ul class="list-group">
		<li class="list-group-item active bg-dark border-dark">
			<h5 class="my-1"><i class="fas fa-mask"></i> Carnival parade costume and addons</h5>
		</li>
		<li class="list-group-item">
			<p class="text-muted.">
				Costume choices will be presented to you once they become accessible.
			</p>
			<div class="row" v-for="index in totalTravellers" :key="index">
				<div class="col-12 mb-3">
					<div class="alert alert-secondary my-3" v-if="index - 1 == 0">
						{{
							`Costume info for ${Array.isArray(travellers.fname) && travellers.fname[index - 1]
								? `${travellers.fname[index - 1]} ${travellers.lname[index - 1]}`
								: "Lead traveller"
							}`
						}}
					</div>
					<div class="alert alert-secondary my-3" v-else>
						{{
							`Costume info for ${Array.isArray(travellers.fname) && travellers.fname[index - 1]
								? `${travellers.fname[index - 1]} ${travellers.lname[index - 1]}`
								: `Traveller number ${index - 1}`
								}`
						}}
					</div>
					<label class="small text-muted">Add costume?*</label>
					<select class="form-select" v-model="selectedCostumes[index - 1]"
						@change="selectedCostumeChange(index - 1)">
						<option disabled selected>Choose one</option>
						<option value="no">No</option>
						<option v-for="(costume, index) in costumeOptions.filter(
							(costume) => costume.quantity > costume.sold
						)" :key="index" :value="costume">
							{{ costume.name }}
						</option>
					</select>
				</div>
				<div class="col-12" v-if="selectedCostumes[index - 1] && selectedCostumes[index - 1] !== 'no'">
					<div class="row">
						<div class="col-12 mb-3" v-if="selectedCostumes[index - 1].gender == 'male'">
							<label class="small text-muted">Pants size*</label>
							<select v-model="selectedCostumesData.pants_size[index - 1]" class="form-select"
								@change="formValueChanged">
								<option disabled selected>Choose one</option>
								<option value="small">Small</option>
								<option value="medium">Medium</option>
								<option value="large">Large</option>
								<option value="x-large">X Large</option>
								<option value="2x-large">2X Large</option>
								<option value="3x-large">3X Large</option>
							</select>
						</div>
						<div v-if="selectedCostumes[index - 1].gender == 'female'">
							<div class="col-12 mb-3">
								<label class="small text-muted">Bra type*</label>
								<select v-model="selectedCostumesData.bra_type[index - 1]" class="form-select"
									@change="formValueChanged">
									<option disabled selected>Choose one</option>
									<option value="regular-bra">Regular bra</option>
									<option value="wire-bra">Wire bra (if available)</option>
									<option value="custom-bra">Custom</option>
								</select>
							</div>
							<div class="col-12 mb-3">
								<label class="small text-muted">Bra size <em>(e.g. 36C)</em>*</label>
								<input v-model.trim="selectedCostumesData.bra_size[index - 1]" class="form-control"
									@input="formValueChanged" />
								<span class="small text-muted"><small>Please note that max. size for most wire bras is
										36DD</small></span>
							</div>
							<div class="col-12 mb-3">
								<label class="small text-muted">Underwear type*</label>
								<select v-model="selectedCostumesData.underwear_type[index - 1]" class="form-select"
									@change="formValueChanged">
									<option disabled selected>Choose one</option>
									<option value="regular-underwear">Regular</option>
									<option value="boyshorts">Boy shorts (if available)</option>
									<option value="thong">Thong (if available)</option>
								</select>
							</div>
							<div class="col-12 mb-3">
								<label class="small text-muted">Underwear size*</label>
								<select v-model="selectedCostumesData.underwear_size[index - 1]" class="form-select"
									@change="formValueChanged">
									<option disabled selected>Choose one</option>
									<option value="small">Small</option>
									<option value="medium">Medium</option>
									<option value="large">Large</option>
									<option value="x-large">X Large</option>
									<option value="2x-large">2X Large</option>
									<option value="3x-large">3X Large</option>
								</select>
							</div>
						</div>
						<div class="col-12 mb-3">
							<label class="small text-muted">Choose add-ons</label>
							<div class="form-check my-3" v-for="addon in addonsOptions.filter(
								(addons) => addons.quantity > addons.sold
							)" :key="addon.id">
								<input class="form-check-input" type="checkbox" :value="{ idx: index - 1, ...addon }"
									:id="`${addon.id}_${index}`" v-model="selectedCostumesData.addons"
									@change="formValueChanged" />
								<label class="form-check-label" :for="`${addon.id}_${index}`">
									{{ addon.name }}
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</li>
	</ul>
</template>

<script>
export default {
	name: "parade-costume",
	components: {},
	computed: {
		totalTravellers() {
			return this.$store.state.totalTravellers;
		},
		travellers() {
			return this.$store.state.travellers;
		},
		costumeOptions() {
			return this.$store.state.costumeOptions;
		},
		addonsOptions() {
			return this.$store.state.addonsOptions;
		},
		validateForm() {
			const {
				pants_size,
				bra_type,
				bra_size,
				underwear_type,
				underwear_size,
			} = this.selectedCostumesData;
			const selectedCostumes = this.selectedCostumes;
			for (let i = 0; i < selectedCostumes.length; i++) {
				if (selectedCostumes[i] != "no") {
					if (selectedCostumes[i].gender == "male" && !pants_size[i]) {
						return false;
					}
					if (selectedCostumes[i].gender == "female" && !bra_type[i]) {
						return false;
					}
					if (selectedCostumes[i].gender == "female" && !bra_type[i]) {
						return false;
					}
					if (selectedCostumes[i].gender == "female" && !bra_size[i]) {
						return false;
					}
					if (selectedCostumes[i].gender == "female" && !underwear_type[i]) {
						return false;
					}
					if (selectedCostumes[i].gender == "female" && !underwear_size[i]) {
						return false;
					}
				}
			}
			return true;
		},
	},
	data() {
		return {
			isBusy: false,
			selectedCostumes: [],
			selectedCostumesData: {
				addons: [],
				pants_size: [],
				bra_type: [],
				bra_size: [],
				underwear_type: [],
				underwear_size: [],
			},
		};
	},
	methods: {
		getCostumePrice() {
			let costumeCost = 0;

			//get costume price
			for (let i = 0; i < this.selectedCostumes.length; i++) {
				costumeCost += this.selectedCostumes[i]?.price || 0;
			}
			//get addons price
			for (let i = 0; i < this.selectedCostumesData.addons.length; i++) {
				costumeCost += this.selectedCostumesData.addons[i]?.price || 0;
			}

			this.$store.commit("setCostumePrice", costumeCost);
			this.$store.dispatch("getTripCost");
		},
		selectedCostumeChange() {
			this.$store.commit("setSelectedCostumes", this.selectedCostumes);
			this.getCostumePrice();
			this.formValueChanged();
		},
		formValueChanged() {
			this.$store.commit("setSelectedAddons", this.selectedCostumesData.addons);
			this.getCostumePrice();
			if (this.validateForm) {
				this.$store.commit("setSelectedCostumesData", this.selectedCostumesData);
			} else {
				this.$store.commit("setSelectedCostumesData", []);
			}
		},
	},
	mount() { },
};
</script>
