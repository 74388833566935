<template>
	<div class="my-3">
		<div class="form-check">
			<input class="form-check-input" type="checkbox" 
			:checked="modelValue" 
			:id="inputId"
			@change="$emit('update:modelValue', $event.target.checked); $emit('clearFormError')">
			<label class="form-check-label" :for="inputId">
				{{label}}<span class="text-danger" v-if="required">*</span>
			</label>
			<div class="text-danger small" v-if="error" :aria-describedby="inputId">
				<em>{{ error }}</em> 
			</div>
		</div>
	</div>
</template>
<script>
import { v4 } from 'uuid';
export default {
	name: 'BaseCheckbox',
	emits: ["update:modelValue", "clearFormError"],
	props: {
		modelValue: {
			type: Boolean,
			default: false,
		},
		label: {
			type: String,
			default: ""
		},
		required: {
			type: Boolean,
			default: false,
		},
		error: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			inputId: `input-${v4()}`,
		};
	}
}
</script>