<template>
    <div class="mb-3">
        <p>
            Fill in and submit the form below to get started. All fields marked with an
            <span class="text-danger">'*'</span> are required.
        </p>
        <p>
            If you are sharing a room with one or more persons, please have their information close by. Each one of your
            roommates is required to book at the same time using the form below. If one or more members of your group
            drop out, the total costs of the rooms will be divided among the group's remaining members.
        </p>
        <p>
            After receiving and reviewing your request, we will send you and the members of your group a link via email
            to make the initial deposit and subsequent payments towards the main package. You can use this link to make
            payments at any time. Payments can be made using a credit card.
        </p>
        <p>All selected add-ons will be processed and fulfilled separately.</p>
        <p>
            You may cancel your booking on or before October 19, 2022, without penalty. Bookings cancelled between
            October 20 and December 01, 2022, inclusive, are subject to a 50% cancellation fee. No refunds will be given
            for ANY reason after December 01, 2022.
        </p>
        <p>You may contact us via WhatsApp at +18683882937 if you have additional questions or require assistance.</p>
    </div>
</template>
<script>
export default {
    name: "RIConventionFormInstructions",
};
</script>
