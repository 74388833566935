<template>
	<div class="mb-3">
		<span class="fs-4 fw-bold."><i class="me-2 fas" :class="`fa-${icon}`" v-if="icon"></i> {{title}}</span>
		<div class="small text-secondary">
			{{subTitle}}
		</div>
	</div>
</template>
<script>
export default {
	name: "BaseListGroupTitle",
	props: {
		title: {
			type: String,
			default: ""
		},
		subTitle: {
			type: String,
			default: ""
		},
		icon: {
			type: String,
			default: ""
		},
	},
}
</script>