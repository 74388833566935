<template>
	<div class="p-md-4 mb-5" id="pageTop">
		<div class="row">
			<div class="col-12">
				<div class="row">
					<div class="col-auto mt-4 h2 pointer" @click="$router.go(-1)">
						<i class="bi bi-chevron-left"></i>
					</div>
					<div class="col p-1 h4 my-2 mb-3">
						<span class="fw-bold h3">{{ selectedEvent.name }} </span><br />
						<span class="h5">Dates and availability.</span>
					</div>
				</div>
				<ul class="list-group list-group-flush">
					<li class="list-group-item" v-if="Array.isArray(availability) && availability.length > 0">
						<div class="row">
							<div class="col-12 card mb-4" v-for="option in  availability " :key="option.id">
								<div class="mt-2" v-if="option?.description">
									{{ option?.description }}
								</div>
								<div class="row card-body small">
									<div class="col-12 col-lg-12 small text-danger" align="right"
										v-if="showRemainingQuantity(option)">
										Only {{ option.quantity - option.sold }}
										{{
											option.quantity - option.sold === 1 ? `spot` : `spots`
										}}
										remaining.
									</div>
									<div class="col-12 col-md-5">
										<span class="small text-muted">Date<br /></span>
										<strong><span class="h5">{{ formatDate(option.start_date, "yyyy") }}</span>
											-
											<span class="h5">
												{{ formatDate(option.start_date, "MMM dd") }} &nbsp;
												<i class="fas fa-chevron-right text-secondary"></i> &nbsp;
												{{ formatDate(option.end_date, "MMM dd") }}
											</span></strong>
									</div>
									<div class="col-6 col-md-2">
										<span class="small text-muted">Starting Price<br /></span>
										<span class="fs-5">{{ formatCurrency(option.base_price) }}</span>
									</div>
									<div class="col-6 col-md-3">
										<span class="small text-muted">Starting Deposit<br /></span>
										<span class="fs-5">{{ getDeposit(option) }}</span>
									</div>
									<div class="col-12 col-md-2 d-grid my-2">
										<div class="col-12 d-grid" align="center" v-if="option.quantity - option.sold < 1">
											<button class="btn btn-outline-danger" disabled>Sold out!</button>
										</div>
										<div class="col-12 d-grid" v-else>
											<router-link class="btn btn-dark" :to="{
												name: 'BookNow', params: { event_id: $route.params.event_id, travel_date_id: option.id, },
											}" target="_blank">Continue</router-link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</li>
					<li class="list-group-item" v-else-if="Array.isArray(availability) && availability.length == 0">
						<div class="row">
							<div class="col-12 text-muted my-5" align="center">
								<em>There aren't any dates available for this trip... </em>
							</div>
						</div>
					</li>
					<li class="list-group-item" v-else>
						<div class="row my-4">
							<div class="col-12 mb-4" v-for=" index  in  3 " :key="index">
								<div class="row small">
									<div class="col-12 col-md-5 my-1">
										<Skeletor height="25" pill />
									</div>
									<div class="col-6 col-md-2 my-1">
										<Skeletor height="25" pill />
									</div>
									<div class="col-6 col-md-3 my-1">
										<Skeletor height="25" pill />
									</div>
									<div class="col-12 col-md-2 d-grid my-1">
										<Skeletor height="25" pill />
									</div>
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>

		<div class="modal fade" id="bookingFormModal" tabindex="-1" aria-labelledby="bookingFormModalLabel"
			aria-hidden="true">
			<div class="modal-dialog modal-fullscreen">
				<div class="modal-content">
					<div class="modal-header border-bottom">
						<h3 class="modal-title d-none d-lg-block" id="bookingFormModalLabel">
							Booking details
						</h3>
						<button type="button" class="btn-lg btn-close d-none d-lg-block" data-bs-dismiss="modal"
							aria-label="Close"></button>
						<div class="d-flex col d-lg-none">
							<div class="flex-grow-1 bold">
								<strong class="fs-5">Total cost:
									{{
										totalTravellers > 0 ? formatCurrency(totalTripCost) : "---"
									}}</strong>
								<br /><button class="btn btn-sm btn-dark" data-bs-toggle="offcanvas"
									data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom">
									View cost per person
								</button>
							</div>

							<div class="my-1">
								<button type="button" class="btn-lg btn-close text-dark" data-bs-dismiss="modal"
									aria-label="Close"></button>
							</div>
						</div>
					</div>
					<div class="modal-body">
						<NewBooking :key="bookingFormKey" />
					</div>

					<div class="offcanvas offcanvas-end" tabindex="-2" id="offcanvasBottom"
						aria-labelledby="offcanvasBottomLabel">
						<div class="offcanvas-header">
							<h5 class="offcanvas-title" id="offcanvasBottomLabel">&nbsp;</h5>
							<button type="button" class="btn-close btn-lg text-reset" data-bs-dismiss="offcanvas"
								aria-label="Close"></button>
						</div>
						<div class="offcanvas-body small">
							<BookingSummary />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import moment from "moment";
import { formatDate, formatCurrency } from "../utils";
import { collection, query, onSnapshot, where } from "firebase/firestore";
import { db, collections, subCollections } from "../firebaseConfig";
import NewBooking from "../components/book/NewBooking.vue";
import BookingSummary from "../components/book/BookingSummary.vue";
export default {
	name: "TravelDates",
	components: { NewBooking, BookingSummary },
	computed: {
		totalTripCost() {
			return this.$store.state.totalTripCost;
		},
		totalTripDeposit() {
			return this.$store.state.totalTripDeposit;
		},
		selectedEvent() {
			return this.$store.state.selectedEvent;
		},
		totalTravellers() {
			return this.$store.state.totalTravellers;
		},
	},
	data() {
		return {
			formatDate,
			formatCurrency,
			isBusy: false,
			bookingFormKey: Date.now(),
			availability: [],
		};
	},
	methods: {
		btnContinueClick(availability) {
			this.$router.push({
				name: "BookNow",
				params: {
					event_id: this.$route.params.event_id,
					travel_date_id: availability.id,
				},
			});
		},
		dateDiff(startDate) {
			const dateDiff = moment.unix(startDate).diff(moment(), "days");
			return dateDiff;
		},
		getDeposit(option) {
			const { base_price, start_date } = option;
			let deposit = 0;
			if (this.dateDiff(start_date) > 90) {
				deposit = base_price * 0.15;
			} else if (this.dateDiff > 60 && this.dateDiff <= 90) {
				deposit = base_price * 0.5;
			} else if (this.dateDiff <= 60) {
				deposit = base_price;
			}

			return formatCurrency(deposit);
		},
		showRemainingQuantity(option) {
			const qtyAvailable = option.quantity - option.sold;
			if (option?.show_remaining_quantity) {
				return true;
			}
			if (qtyAvailable > 0 && qtyAvailable < 11) {
				return true;
			}
			return false;
		},
	},
	created() {
		const eventId = this.$route.params.event_id;
		const datesRef = query(
			collection(db, `${collections.EVENTS}/${eventId}/${subCollections.AVAILABILITY}`),
			where("public", "==", true)
		);
		onSnapshot(
			datesRef,
			(querySnapshot) => {
				let availability = [];
				querySnapshot.forEach((doc) => {
					const startDate = doc.data().start_date;
					const currentDate = Date.now();
					if (startDate > currentDate) {
						availability.push({ id: doc.id, ...doc.data() });
					}
				});
				this.availability = availability.sort((a, b) =>
					a.start_date > b.start_date ? 1 : a.start_date < b.start_date ? -1 : 0
				);
			},
			(error) => {
				console.log(error);
			}
		);
	},
	mounted() {
		this.$store.commit("setPageTitle", "Book Now");
	},
};
</script>
<style>
.app-logo {
	width: 70px !important;
	height: auto;
}

.modal-header {
	border-bottom: 0 !important;
}
</style>
