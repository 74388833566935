<template>
    <div class="p-1 p-md-4 mb-5" id="pageTop">
        <BasePageTitle
            hideBackButton
            title="Rotary WIndies Tour of Australia"
            subTitle="May 21 to June 02, 2023, Melbourne, Australia."
        />
        <div class="row">
            <div class="offset-lg-3. col-lg-7">
                <div class="row">
                    <div v-if="!showForm">
                        <ul class="list-group my-2">
                            <li class="list-group-item bg-secondary. text-white.">
                                <BaseListGroupTitle icon="info" title="Instructions and terms" />
                                <RIConventionFormInstructions />
                                <div class="my-3">
                                    <BaseButton label="Continue" fullWidth size="lg" @click="btnContinueClick" />
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div v-if="showForm">
                        <ul class="list-group my-2">
                            <li class="list-group-item">
                                <BaseListGroupTitle icon="hotel" title="Package and room information" />
                                <!--  <div
                                    class="small btn btn-link ps-0 text-decoration-none btn-sm"
                                    @click="$router.push('/book/ri-convention/packages')"
                                >
                                    <i class="fas fa-info"></i> Compare packages
                                </div> -->
                                <BaseSelect
                                    label="Package option"
                                    required
                                    :options="tourPackages"
                                    v-model="booking.tour_package"
                                    @change="computeFees"
                                    :error="formErrors?.tour_package"
                                    @clearFormError="clearFormError('tour_package')"
                                />
                                <div class="alert alert-info fw-bold" v-if="booking.tour_package">
                                    {{ tourPackagePrice }}
                                </div>
                                <div class="w-100 my-4"></div>
                                <div class="fs-5">Add-ons</div>
                                <BaseCheckbox
                                    label="Business Class seat upgrade ($7,399 USD)"
                                    v-model="booking.business_class_upgrade"
                                />
                                <BaseCheckbox
                                    label="Travel Insurance (starts at $249 from CG United.)"
                                    v-model="booking.travel_insurance"
                                />
                                <BaseCheckbox
                                    label="Assistance with booking round-trip flight to LAX (Cost provided on request)."
                                    v-model="booking.lax_flight_assistance"
                                />
                            </li>
                        </ul>
                    </div>
                    <div v-show="booking.tour_package && showForm">
                        <ul class="list-group my-2">
                            <li class="list-group-item">
                                <BaseListGroupTitle icon="users" title="Personal information" />
                                <template v-for="(traveller, index) in travellers" :key="index">
                                    <div>
                                        <div class="alert alert-info py-2">
                                            Traveller no. {{ index + 1 }}
                                            <button
                                                class="btn btn-link btn-sm text-decoration-none"
                                                v-if="showTravellersToggle && index > 0"
                                            >
                                                <i class="fa fa-trash"></i> Remove
                                            </button>
                                        </div>
                                        <BaseInput
                                            label="First name"
                                            v-model="traveller.fname"
                                            required
                                            :error="formErrors[`traveller-${index}-fname`]"
                                            @clearFormError="clearFormError(`traveller-${index}-fname`)"
                                        />
                                        <BaseInput
                                            label="Last name"
                                            v-model="traveller.lname"
                                            required
                                            :error="formErrors[`traveller-${index}-lname`]"
                                            @clearFormError="clearFormError(`traveller-${index}-lname`)"
                                        />
                                        <BaseInput
                                            label="Email address"
                                            v-model="traveller.email"
                                            required
                                            :error="formErrors[`traveller-${index}-email`]"
                                            @clearFormError="clearFormError(`traveller-${index}-email`)"
                                        />
                                        <BaseInput
                                            label="Mobile number"
                                            v-model="traveller.phone"
                                            required
                                            :error="formErrors[`traveller-${index}-phone`]"
                                            @clearFormError="clearFormError(`traveller-${index}-phone`)"
                                        />
                                        <BaseSelect
                                            label="Country"
                                            :options="countryList"
                                            v-model="traveller.country"
                                            required
                                            :error="formErrors[`traveller-${index}-country`]"
                                            @clearFormError="clearFormError(`traveller-${index}-country`)"
                                        />
                                        <BaseInput
                                            label="Age in 2023"
                                            v-model="traveller.age_in_2023"
                                            required
                                            :error="formErrors[`traveller-${index}-age_in_2023`]"
                                            @clearFormError="clearFormError(`traveller-${index}-age_in_2023`)"
                                        />
                                        <BaseInput
                                            label="Rotary or Rotaract Club"
                                            v-model="traveller.rotary_club"
                                            required
                                            :error="formErrors[`traveller-${index}-rotary_club`]"
                                            @clearFormError="clearFormError(`traveller-${index}-rotary_club`)"
                                        />
                                        <BaseSelect
                                            label="Polo shirt size"
                                            :options="tshirtSizes"
                                            v-model="traveller.shirt_size"
                                            required
                                            :error="formErrors[`traveller-${index}-shirt_size`]"
                                            @clearFormError="clearFormError(`traveller-${index}-shirt_size`)"
                                        />
                                        <BaseSelect
                                            label="Are you registered for R.I. Convention?"
                                            :options="['Yes', 'No']"
                                            v-model="traveller.registered_for_conference"
                                            required
                                            :error="formErrors[`traveller-${index}-registered_for_conference`]"
                                            @clearFormError="
                                                clearFormError(`traveller-${index}-registered_for_conference`)
                                            "
                                        />
                                        <BaseTextArea label="Comments /Special Requests" v-model="traveller.notes" />
                                    </div>
                                </template>
                                <div>
                                    <BaseButton
                                        label="Add another traveller"
                                        @click="btnAddTravellerClick"
                                        v-if="showTravellersToggle"
                                    />
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div v-if="showForm">
                        <ul class="list-group my-2">
                            <li class="list-group-item">
                                <BaseListGroupTitle
                                    icon="calendar-alt"
                                    title="Choose payment schedule"
                                    subTitle="We are providing you with the most flexible payment plan options, making it super easy and affordable to book your Rotary WIndies experience. The cost of any selected add-ons is not included in the prices shown below."
                                />
                                <ul class="list-group my-2" v-if="booking.tour_package">
                                    <li class="list-group-item">
                                        <BaseRadio
                                            :label="txtPayInFull"
                                            :value="txtPayInFull"
                                            name="paymentPlan"
                                            v-model="booking.payment_plan"
                                        />
                                    </li>
                                    <li class="list-group-item">
                                        <BaseRadio
                                            :label="txtPayInThree"
                                            :value="txtPayInThree"
                                            name="paymentPlan"
                                            v-model="booking.payment_plan"
                                        />
                                    </li>
                                    <li class="list-group-item">
                                        <BaseRadio
                                            :label="txtPayMonthly"
                                            :value="txtPayMonthly"
                                            name="paymentPlan"
                                            v-model="booking.payment_plan"
                                        />
                                    </li>
                                </ul>
                                <div class="alert" v-else>
                                    <em>Please select a tour package first to view payment schedule options.</em>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div v-if="showForm">
                        <ul class="list-group my-2">
                            <li class="list-group-item">
                                <BaseListGroupTitle icon="gavel" title="Legal stuff" />
                                <BaseCheckbox
                                    label="I agree to the terms and conditions for this trip."
                                    v-model="booking.agree_terms"
                                    required
                                    :error="formErrors?.agree_terms"
                                    @clearFormError="clearFormError('agree_terms')"
                                />
                                <BaseCheckbox
                                    label="I agree to the cancellation and chargeback policies for this trip."
                                    v-model="booking.agree_cancelation_policies"
                                    required
                                    :error="formErrors?.agree_cancelation_policies"
                                    @clearFormError="clearFormError('agree_cancelation_policies')"
                                />
                                <BaseCheckbox
                                    label="I agree to allow Soca Islands to process my data."
                                    v-model="booking.data_consent"
                                    required
                                    :error="formErrors?.data_consent"
                                    @clearFormError="clearFormError('data_consent')"
                                />
                            </li>
                        </ul>
                    </div>
                    <div v-if="showForm">
                        <div class="mb-5">
                            <BaseButton label="Submit" fullWidth size="lg" :busy="formBusy" @click="submitForm" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import { collection, addDoc } from "firebase/firestore";
import { db, collections } from "../firebaseConfig";
import { object, string, boolean, number } from "yup";
import { differenceInCalendarMonths, addDays, format } from "date-fns";
import { formatCurrency } from "../utils";
import PaymentMethod from "../components/common/PaymentMethod.vue";
import BasePageTitle from "../components/common/BasePageTitle.vue";
import BaseInput from "../components/common/BaseInput.vue";
import BaseTextArea from "../components/common/BaseTextArea.vue";
import BaseSelect from "../components/common/BaseSelect.vue";
import BaseCheckbox from "../components/common/BaseCheckbox.vue";
import BaseRadio from "../components/common/BaseRadio.vue";
import BaseListGroupTitle from "../components/common/BaseListGroupTitle.vue";
import BaseDataList from "../components/common/BaseDataList.vue";
import BaseButton from "../components/common/BaseButton.vue";
import RIConventionFormInstructions from "../components/RIConventionFormInstructions.vue";

const tshirtSizeOptions = {
    FEMALESMALL: "Female Small",
    FEMALEMED: "Female Medium",
    FEMALELARGE: "Female Large",
    FEMALEXLARGE: "Female X Large",
    FEMALE2XLARGE: "Female XX Large",
    FEMALE3XLARGE: "Female XXX Large",
    MALESMALL: "Male Small",
    MALEMED: "Male Medium",
    MALELARGE: "Male Large",
    MALEXLARGE: "Male X Large",
    MALE2XLARGE: "Male XX Large",
    MALE3XLARGE: "Male XXX Large",
};

const roomOptions = {
    SINGLE: "Single Occupancy",
    DOUBLE: "Double Occupancy - one king or queen bed",
    DOUBLE2: "Double Occupancy - two queen beds",
};

const tourPackageOptions = {
    COVERED: {
        id: "covered-single-occupancy",
        label: "Via L.A. - Single Occupancy",
        price: 6540,
        room_capacity: 1,
    },
    COVERED1: {
        id: "covered-double-occupancy-one-bed",
        label: "Via L.A.  - Double Occupancy, 1 Bed",
        price: 5340,
        room_capacity: 2,
    },
    COVERED2: {
        id: "covered-double-occupancy-two-beds",
        label: "Via L.A. - Double Occupancy, 2 Beds",
        price: 5340,
        room_capacity: 2,
    },
    SYDNEY: {
        id: "sydney-single-occupancy",
        label: "Via Sydney - Single Occupancy",
        price: 4560,
        room_capacity: 1,
    },
    SYDNEY1: {
        id: "sydney-double-occupancy-one-bed",
        label: "Via Sydney - Double Occupancy, 1 Bed",
        price: 3360,
        room_capacity: 2,
    },
    SYDNEY2: {
        id: "sydney-double-occupancy-two-beds",
        label: "Via Sydney - Double Occupancy, 2 Beds",
        price: 3360,
        room_capacity: 2,
    },
};

const rotaryClubs = ["Not Applicable", "RC Antigua"];

const countryList = [
    "Anguilla",
    "Antigua and Barbuda",
    "Aruba",
    "Bahamas",
    "Barbados",
    "Belize",
    "Bermuda",
    "Bonaire",
    "British Virgin Islands",
    "Cayman Islands",
    "Curacao",
    "Dominica",
    "French Guiana",
    "Grenada",
    "Guadeloupe",
    "Guyana",
    "Haiti",
    "Jamaica",
    "Martinique",
    "Montserrat",
    "Puerto Rico",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin",
    "Saint Vincent and the Grenadines",
    "Sint Maarten",
    "Suriname",
    "Trinidad and Tobago",
    "Turks and Caicos Islands",
    "United States Virgin Islands",
    "Other",
];

export default defineComponent({
    name: "RIConvention",
    components: {
        PaymentMethod,
        BasePageTitle,
        BaseInput,
        BaseTextArea,
        BaseSelect,
        BaseCheckbox,
        BaseListGroupTitle,
        BaseRadio,
        BaseDataList,
        BaseButton,
        RIConventionFormInstructions,
    },
    computed: {
        isBusy() {
            return this.$store.state.isReservationFormBusy;
        },
        tourPackagePrice() {
            const tourPackages = Object.entries(tourPackageOptions).map(([key, value]) => {
                console.log("line 377", value);
                return {
                    id: value?.id,
                    price: value.price,
                };
            });
            const packagePrice = tourPackages.find((option) => option?.id === this.booking.tour_package)?.price;
            return `Price per person - ${formatCurrency(packagePrice)}`;
        },
        agreetoTerms() {
            return this.agree_terms && this.agree_cancelation_policies && this.data_consent;
        },
        txtIntro() {
            return this.travellers.length > 1 ? "Each person pays" : "Pay";
        },
        txtPayInFull() {
            return `${this.txtIntro} the full amount, ${formatCurrency(this.totalCost)}`;
        },
        txtPayInThree() {
            const totalCost = this.totalCost;
            const deposit = 1000;
            /* const secondPayment = 600;
            const finalPayment = totalCost / 2;
            const secondPaymentDate = format(addDays(new Date(), 30), "MMMM d, yyyy");
            const paymentDeadline = "March 01st, 2023";
            const txtInfo = `${this.txtIntro} ${formatCurrency(deposit)} on or before October 15th, ${formatCurrency(
                secondPayment
            )} on or before November 15th, and  ${formatCurrency(finalPayment)} on or before ${paymentDeadline}.`;
            return txtInfo; */
            const monthsToPay = differenceInCalendarMonths(new Date(2023, 2, 1), new Date());
            const txtInfo = `(Via Los Angeles Option) - ${this.txtIntro} ${formatCurrency(
                deposit
            )} on or before October 15th, then pay ${formatCurrency(
                (totalCost - deposit) / monthsToPay
            )} monthly for ${monthsToPay} months.`;
            return txtInfo;
        },
        txtPayMonthly() {
            const totalCost = this.totalCost;
            const deposit = 1000;
            const monthsToPay = differenceInCalendarMonths(new Date(2023, 2, 1), new Date());
            const txtInfo = `(Via Sydney Option) - ${this.txtIntro} ${formatCurrency(
                deposit
            )} on or before October 15th, then pay ${formatCurrency(
                (totalCost - deposit) / monthsToPay
            )} monthly for ${monthsToPay} months.`;
            return txtInfo;
        },
        showTravellersToggle() {
            return this.booking.tour_package === tourPackageOptions.UNCOVERED?.id;
        },
        tshirtSizes() {
            const tshirtSizes = Object.values(tshirtSizeOptions);
            return tshirtSizes;
        },
        roomTypes() {
            const roomTypes = Object.values(roomOptions);
            return roomTypes;
        },
        tourPackages() {
            const tourPackages = Object.entries(tourPackageOptions).map(([key, value]) => {
                console.log("line 438 ", value);
                return {
                    value: value?.id,
                    label: value?.label,
                };
            });
            return tourPackages;
        },
    },
    data() {
        const phoneRegExp =
            /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
        const bookingSchema = object().shape({
            tour_package: string().required("Please select a package"),
            payment_plan: string().required("Please select a payment plan"),
            agree_terms: boolean().oneOf([true], "You must agree to the terms and conditions"),
            agree_cancelation_policies: boolean().oneOf([true], "You must agree to the cancelation policies"),
            data_consent: boolean().oneOf([true], "You must agree to the processing of your data"),
        });
        const travellerSchema = object().shape({
            fname: string().required("First name is required"),
            lname: string().required("Last name is required"),
            email: string("Please enter your email address").required().email("Invalid email address"),
            phone: string()
                .required("Please enter your phone number")
                .matches(phoneRegExp, "Phone number is not valid"),
            age_in_2023: number()
                .required("Please enter your age in 2023")
                .min(18, "You must be 18 years old or older"),
            rotary_club: string().required("Please select the name of your club or enter 'NA'"),
            shirt_size: string().required("Please select a shirt size"),
            country: string().required("Please select a country"),
            registered_for_conference: string().required("Please select if you are registered for the conference"),
        });
        return {
            apiURL: "https://app.socaislands.com/api",
            tourPackageOptions,
            rotaryClubs,
            countryList,
            bookingSchema,
            travellerSchema,
            showForm: false,
            totalCost: 0,
            formBusy: false,
            tourPackageName: "",
            booking: {
                business_class_upgrade: false,
                travel_insurance: false,
                aus_visa_assistance: false,
                lax_flight_assistance: false,
                payment_plan: "Pay in full",
                agree_terms: false,
                agree_cancelation_policies: false,
                data_consent: false,
                email_consent: true,
                role: "subscriber",
                tour_package: "",
                new_zealand_extension: false,
            },
            travellers: [],
            travellerModel: {
                fname: "",
                email: "",
                phone: "",
                shirt_size: "",
                country: "",
                rotary_club: "",
                notes: "",
            },
            formErrors: {},
        };
    },
    methods: {
        clearFormError(field) {
            this.formErrors[field] = "";
        },
        btnContinueClick() {
            this.showForm = true;
            window.scrollTo(0, 0);
        },
        async submitForm() {
            const { booking, travellers, travellerSchema, bookingSchema, tourPackageName } = this;
            this.formBusy = true;
            this.formErrors = {};

            bookingSchema.validate(booking, { abortEarly: false }).catch((err) => {
                this.formErrors = err.inner.reduce(
                    (accum, currentVal) => {
                        accum[currentVal.path] = currentVal.message;
                        return accum;
                    },
                    { ...this.formErrors }
                );
            });

            travellers.forEach((traveller, index) => {
                travellerSchema.validate(traveller, { abortEarly: false }).catch((err) => {
                    this.formErrors = err.inner.reduce(
                        (accum, currentVal) => {
                            accum[`traveller-${index}-${currentVal.path}`] = currentVal.message;
                            return accum;
                        },
                        { ...this.formErrors }
                    );
                });
            });

            if (
                bookingSchema.isValidSync(booking) &&
                travellers.every((traveller) => travellerSchema.isValidSync(traveller))
            ) {
                console.log("Form is valid");
                const newDate = Date.now();
                const created_at = newDate;
                const updated_at = newDate;
                const bookingData = {
                    ...booking,
                    travellers,
                    created_at,
                    updated_at,
                };

                const emailList = travellers
                    .map((traveller) => {
                        return traveller.email;
                    })
                    .toString();

                let fnameList = travellers
                    .map((traveller) => {
                        return `${traveller.fname}`;
                    })
                    .toString();

                fnameList = fnameList.replace(/,/g, ", "); // replace all commas with a comma and space
                fnameList = fnameList.replace(/,(?=[^,]*$)/, " and"); // replace the last comma with 'and'

                try {
                    const docRef = await addDoc(collection(db, collections.RICONVENTION), bookingData);
                    const apiRef = `${this.apiURL}/email/riconvention/${encodeURI(emailList)}!${encodeURI(
                        fnameList
                    )}!${encodeURI(tourPackageName)}`;
                    const emailResponse = await fetch(apiRef);
                    console.log("Email API call ", apiRef);
                    console.log("Email response ", emailResponse);
                    console.log("Document written with ID: ", docRef.id);
                    this.$router.push("/book/ri-convention/success");
                } catch (err) {
                    console.error("Error adding document: ", err);
                    this.formBusy = false;
                }
            } else {
                console.log("form is invalid");
                this.formBusy = false;
                window.scrollTo(0, 0);
            }
        },
        btnAddTravellerClick() {
            this.travellers.push({ ...this.travellerModel });
        },
        computeFees() {
            const { travellers, btnAddTravellerClick } = this;
            const { tour_package } = this.booking;
            const { price, room_capacity, label } = Object.values(tourPackageOptions).find(
                (tp) => tp?.id === tour_package
            );
            this.totalCost = price;
            this.tourPackageName = label;
            if (room_capacity > travellers.length) {
                while (room_capacity > travellers.length) {
                    btnAddTravellerClick();
                }
            } else if (room_capacity < travellers.length) {
                this.travellers.length = room_capacity;
            }
        },
        updateRotaryClub(index) {
            this.travellers[index].country === "Not Applicable"
                ? (this.travellers[index].rotary_club = "Not Applicable")
                : (this.travellers[index].rotary_club = "");
        },
    },
    created() {},
    mounted() {
        this.$store.commit("setPageTitle", "RI Convention");
        this.btnAddTravellerClick();
    },
});
</script>
<style>
.app-logo {
    width: 70px !important;
    height: auto;
}
.modal-header {
    border-bottom: 0 !important;
}
</style>
