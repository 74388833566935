<template>
	<ul class="list-group">
		<li class="list-group-item active bg-dark border-dark">
			<h5 class="my-1"><i class="fas fa-calendar-alt"></i> Choose payment schedule</h5>
		</li>
		<li class="list-group-item">
			<p class="" v-if="timeToTrip > 60">
				Soca Islands offers the most flexible payment plan, ensuring a convenient and
				budget-friendly way to reserve your {{ tripInfo.name }} adventure.
			</p>
			<p class="text-muted small" v-if="timeToTrip <= 60">
				For bookings made 60 days or fewer to departure, you are required to make the full
				payment to confirm your spot.
			</p>
			<ul class="list-group my-4">
				<li class="list-group-item">
					<div class="form-check">
						<input class="form-check-input" v-model="payFullAmount" :value="true" type="radio"
							name="payFullAmount" id="payFullAmount" @change="paymentScheduleChange" />
						<label class="form-check-label" for="payFullAmount">
							Pay the full amount of {{ formatCurrency(tripCost) }} USD now.
						</label>
					</div>
				</li>
				<li class="list-group-item" v-if="timeToTrip > 60">
					<div class="form-check">
						<input class="form-check-input" v-model="payFullAmount" :value="false" type="radio"
							name="payFullAmount" id="payDeposit" @change="paymentScheduleChange" />
						<label class="form-check-label" for="payDeposit">
							Pay {{ formatCurrency(tripDeposit) }} today
							<span v-if="timeToTrip > 90">, {{ formatCurrency(tripCost * 0.35) }} on or before
								{{ thirtyDays }}</span>
							and {{ formatCurrency(tripCost * 0.5) }} on or before {{ paymentDeadline }}.
						</label>
					</div>
				</li>
			</ul>
		</li>
	</ul>
</template>

<script>
import { formatCurrency, formatDate } from "../../utils";
import moment from "moment";
export default {
	name: "payment-schedule",
	components: {},
	computed: {
		totalTravellers() {
			return this.$store.state.totalTravellers;
		},
		timeToTrip() {
			/* const timeToTrip = moment.unix(this.tripAvailability.start_date).diff(moment(), "days");
				  return timeToTrip; */
			return this.$store.state.timeToTrip;
		},
		thirtyDays() {
			return moment().add(30, "days").format("MMM D, yyyy");
		},
		paymentDeadline() {
			return moment(this.tripAvailability.start_date)
				.subtract(60, "days")
				.format("MMM D, yyyy");
		},
		tripCost() {
			return this.$store.state.totalTripCost;
		},
		tripInfo() {
			return this.$store.state.selectedEvent;
		},
		tripAvailability() {
			return this.$store.state.availability;
		},
		tripDeposit() {
			return this.$store.state.totalTripDeposit;
		},
	},
	data() {
		return {
			formatDate,
			formatCurrency,
			isBusy: false,
			payFullAmount: null,
		};
	},
	methods: {
		paymentScheduleChange() {
			this.$store.commit("setPayFullAmount", this.payFullAmount);
			this.$store.dispatch("getTripCost");
		},
	},
	mounted() { },
};
</script>
