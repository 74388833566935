<template>
  <div class="my-3">
    <div class="form-check">
      <input
        class="form-check-input"
        type="radio"
        :checked="modelValue === value"
        :value="value"
        :id="inputId"
        @change="$emit('update:modelValue', value); $emit('clearFormError')"
        v-bind="$attrs"
      />
      <label class="form-check-label" :for="inputId"> {{label}} </label>
    </div>
    <div class="text-danger small" v-if="error" :aria-describedby="inputId">
			<em>{{ error }}</em> 
		</div>
  </div>
</template>
<script>
  import { v4 } from "uuid";
  export default {
    name: "BaseRadio",
    emits: ["update:modelValue", "clearFormError"],
    props: {
      modelValue: {
        type: [String, Number],
        default: "",
      },
      label: {
        type: String,
        default: "",
      },
      value: {
        type: [String, Number],
        required: true,
      },
      error: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
        inputId: `input-${v4()}`,
      };
    },
  };
</script>
