<template>
	<div class="row">
		<div class="ps-3">
			<div class="row mb-3">
				<div class="col-auto mt-3 h2 pointer" @click="$router.go(-1)" v-if="!hideBackButton">
					<i class="bi bi-chevron-left"></i>
				</div>
				<div class="col p-1 h4 my-2 mb1-3">
					<span class="fw-bold h2">{{title}}</span>
					<br/><small class="fs-6">{{subTitle}}</small>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'BasePageTitle',
	props: {
		title: {
			type: String,
			default: 'Page Title'
		},
		subTitle: {
			type: String,
			default: 'Page Subtitle'
		},
		hideBackButton: {
			type: Boolean,
			default: false
		}
	}
}
</script>