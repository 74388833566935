<template>
	<ul class="list-group">
		<li class="list-group-item active bg-dark border-dark">
			<h5 class="my-1"><i class="fas fa-bus-alt"></i> Transportation</h5>
		</li>
		<li class="list-group-item">
			<p class="">
				You have the choice of both group and private transportation options.
			</p>
			<div class="row">
				<div class="col-12">
					<label class="small text-muted">Transport option* </label>
					<select class="form-select" v-model="selectedTransport" @change="selectedTransportChange"
						:disabled="selectedHotel?.price < 1">
						<option v-for="(transport, index) in transportOptions" :key="index" :value="transport">
							{{ transport.name }}
						</option>
					</select>
					<br />
				</div>
			</div>
		</li>
	</ul>
</template>

<script>
export default {
	name: "transport-type",
	components: {},
	computed: {
		totalTravellers() {
			return this.$store.state.totalTravellers;
		},
		transportOptions() {
			return this.$store.state.transportOptions;
		},
		selectedHotel() {
			this.initTransport(this.$store.state.selectedHotel);
			return this.$store.state.selectedHotel;
		},
	},
	data() {
		return {
			isBusy: false,
			selectedTransport: {},
		};
	},
	methods: {
		selectedTransportChange() {
			const selectedTransport = this.selectedTransport;
			delete selectedTransport.quantity;
			delete selectedTransport.sold;
			this.$store.commit("setSelectedTransport", selectedTransport);
			this.$store.dispatch("getTripCost");
		},
		initTransport(selectedHotel) {
			//check if the private transport option needs to be selected by default
			if (selectedHotel?.price < 1) {
				this.selectedTransport = this.transportOptions.find((option) =>
					option.name.toLowerCase().includes("private")
				);
			}
		},
	},
	mounted() {
		console.log("here again");
	},
};
</script>
