<template>
  <div class="my-2" :class="[{'d-grid': fullWidth}]">
    <button
      class="btn"
      :class="[`btn-outline-${color}`, {'btn-lg': size === 'lg'}]"
      :disabled="busy"
    >
      <span v-if="!busy">{{ label }}</span>
      <div v-else class="spinner-border text-dark" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </button>
  </div>
</template>
<script>
  import { v4 } from "uuid";
  export default {
    name: "BaseRadio",
    props: {
      label: {
        type: String,
        default: "Submit",
      },
      startIcon: {
        type: String,
        default: "",
      },
      endIcon: {
        type: String,
        default: "",
      },
      type: {
        type: String,
        default: "text",
      },
      color: {
        type: String,
        default: "dark",
      },
      name: {
        type: [String, Boolean, Number],
        default: false,
      },
      size: {
        type: String,
        default: "",
      },
      fullWidth: {
        type: Boolean,
        default: false,
      },
      busy: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        inputId: `input-${v4()}`,
      };
    },
  };
</script>
