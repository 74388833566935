<template>
	<div class="my-2">
		<div class="form-floating mb-3">
			<textarea :type="type" class="form-control" 
			:id="inputId" :placeholder="label" 
			:style="`height:${rows}px`" 
			@input="$emit('update:modelValue', $event.target.value); "/>
			<label :for="inputId">{{label}}</label>
		</div>
	</div>
</template>
<script>
import { v4 } from 'uuid';
export default {
	name: 'BaseInput',
	emits: ["update:modelValue", "clearFormError", "onChange"],
	props: {
		modelValue: {
			type: [String, Number],
			default: "",
		},
		label: {
			type: String,
			default: ""
		},
		type:{
			type: String,
			default: "text"
		},
		rows:{
			type: String,
			default: "100"
		},
	},
	data() {
		return {
			inputId: `input-${v4()}`,
		};
	}
}
</script>