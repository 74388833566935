<template>
	<div class="my-2">
		<label :for="inputId" class="form-label small text-secondary" v-if="label">{{label}}</label>
		<input class="form-control" :list="optionsId" :id="inputId" :placeholder="placeholder">
		<datalist :id="optionsId">
			<option value="Not Applicable"/>
			<option value="New York"/>
			<option value="Seattle"/>
			<option value="Los Angeles"/>
			<option value="Chicago"/>
		</datalist>
	</div>
</template>
<script>
import { v4 } from 'uuid';
export default {
	name: 'BaseInput',
	props: {
		label: {
			type: String,
			default: ""
		},
		type:{
			type: String,
			default: "text"
		},
		options:{
			type: Array,
			default: () => []
		},
		placeholder:{
			type: String,
			default: ""
		},
		disabled:{
			type: Boolean,
			default: false
		},
	},
	data() {
		return {
			inputId: `input-${v4()}`,
			optionsId: `options-${v4()}`,
		};
	}
}
</script>