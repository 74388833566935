<template>
	<div class="mb-5" id="pageTop">
		<div class="col-12">
			<ChooseAccommodation />
		</div>
		<div class="col-12 my-4" v-if="totalTravellers > 0">
			<TransportType />
		</div>
		<div class="col-12 my-4" v-if="totalTravellers > 0 &&
			$store.state.selectedEvent.experience_category === 'carnival'
			">
			<ParadeCostume />
		</div>

		<div class="col-12 my-4" v-if="totalTravellers > 0">
			<PaymentSchedule />
		</div>

		<div class="col-12 my-4" v-if="totalTravellers > 0">
			<TravellerDetails v-model:customerFormValues="customerFormValues" />
		</div>

		<div class="col-12 my-4" v-show="totalTravellers > 0">
			<ul class="list-group">
				<li class="list-group-item active bg-dark border-dark">
					<h5 class="my-1"><i class="fas fa-gavel"></i> Legal stuff</h5>
				</li>
				<li class="list-group-item">
					<div class="mt-4 alert. alert-danger. text-danger"
						v-if="$store.state.selectedEvent.experience_category === 'carnival'">
						This rate is NON-REFUNDABLE after 48 hours. If you change or cancel your
						booking after 48 hours, you will not get a refund or credit to use for a
						future activity. This policy will apply regardless of COVID-19, subject to any
						local consumer laws.
					</div>

					<div class="alert alert-light mb-4">
						<div class="form-check my-3">
							<input class="form-check-input" type="checkbox" value="YES" id="agreeTermsandConditions"
								v-model="legal.terms" />
							<label class="form-check-label text-dark" for="agreeTermsandConditions">
								I agree to Soca Islands's
								<a href="https://socaislands.com/legal-stuff/" target="_terms" class="text-dark">Customer
									Terms and Conditions
									<span class="small"><small><i class="fas fa-external-link-alt"></i></small></span></a>,
								<a href="https://socaislands.com/legal-stuff/#customer-agreement" class="text-dark"
									target="_terms">Customer Participation Agreement
									<span class="small"><small><i class="fas fa-external-link-alt"></i></small></span></a>,
								and
								<a href="https://socaislands.com/legal-stuff/#customer-code-of-conduct" class="text-dark"
									target="_terms">Code of Conduct
									<span class="small"><small><i class="fas fa-external-link-alt"></i></small></span></a>.
							</label>
						</div>
						<div class="form-check my-3">
							<input class="form-check-input" type="checkbox" value="" id="agreeCovidTerms"
								v-model="legal.covidTerms" />
							<label class="form-check-label text-dark" for="agreeCovidTerms">
								I agree to Soca Islands's
								<a href="https://socaislands.com/frequently-asked-questions/#refunds-cancellations"
									class="text-dark" target="_terms">Payment Terms
									<span class="small"><small><i class="fas fa-external-link-alt"></i></small></span>,
									Refund Policy
									<span class="small"><small><i class="fas fa-external-link-alt"></i></small></span>
									and Chargeback Policy.
									<span class="small"><small><i class="fas fa-external-link-alt"></i></small></span></a>.
							</label>
						</div>
						<div class="form-check my-3">
							<input class="form-check-input" type="checkbox" value="YES" id="agreeProcessData"
								v-model="legal.privacy" />
							<label class="form-check-label text-dark" for="agreeProcessData">
								I give consent to Soca Islands to process my personal data and agree to
								Soca Islands's
								<a href="https://socaislands.com/legal-stuff/#privacy-policy" class="text-dark"
									target="_terms">Privacy Policy
									<span class="small"><small><i class="fas fa-external-link-alt"></i></small></span></a>.
							</label>
						</div>
						<div class="form-check my-3">
							<input class="form-check-input" type="checkbox" value="YES" id="agreeThirdPartyTerms"
								v-model="legal.thirdPartyTerms" />
							<label class="form-check-label text-dark" for="agreeThirdPartyTerms">
								I agree to the third-party terms and conditions of Soca Islands's vendors
								and partners.
							</label>
						</div>
						<div class="form-check my-3">
							<input class="form-check-input" type="checkbox" value="YES" id="agreeInsurance"
								v-model="legal.insurance" />
							<label class="form-check-label text-dark" for="agreeInsurance">
								I agree to acquire the appropriate travel insurance for my trip within six
								(6) days of making my first payment to Soca Islands.
								<a href="https://socaislands.com/frequently-asked-questions/#travel-insurance"
									class="text-dark" target="_terms">Click here to find out what your insurance should at
									least cover
									<span class="small"><small><i class="fas fa-external-link-alt"></i></small></span></a>.
							</label>
						</div>
					</div>
				</li>
			</ul>
		</div>

		<div class="col-12 my-4 mb-5" v-show="totalTravellers > 0">
			<ul class="list-group">
				<li class="list-group-item active bg-dark border-dark">
					<h5 class="my-1"><i class="fas fa-money-check-alt"></i> Payment method</h5>
				</li>
				<li class="list-group-item">
					<p class="">
						A payment processing fee is
						applied to all credit card payments on our website. If you do not wish to be charged this fee,
						please
						use Zelle Quickpay to submit your payment.
					</p>
					<PaymentMethod class="mb-4" captureMethod="manual" ref="paymentMethodRef" />

					<div class="col-12 d-grid">
						<div class="mb-4 text-danger small"
							v-if="$store.state.selectedEvent.experience_category === 'carnival'">
							By clicking on the button below, I acknowledge all payments made are
							NON-REFUNDABLE 48 hours after my initial deposit. If I change or cancel my
							booking I will not get a refund or credit to use for a future activity. This
							policy will apply regardless of COVID-19, subject to any local consumer
							laws. I agree to purchase an appropriate travel insurance policy. I also
							acknowledge that I have reviewed the
							<a href="https://socaislands.com/legal-stuff/#privacy-policy" class="text-reset"
								target="_terms">Privacy Policy
								<span class="small"><small><i class="fas fa-external-link-alt"></i></small></span></a>
							and
							<a href="https://socaislands.com/frequently-asked-questions/#refunds-cancellations"
								class="text-reset" target="_terms">Refund Policy
								<span class="small"><small><i class="fas fa-external-link-alt"></i></small></span></a>
							and have reviewed and accept Soca Islands's
							<a href="https://socaislands.com/legal-stuff/" target="_terms" class="text-reset">Customer Terms
								and Conditions
								<span class="small"><small><i class="fas fa-external-link-alt"></i></small></span></a>,
							<a href="https://socaislands.com/legal-stuff/#customer-agreement" target="_terms"
								class="text-reset">Customer Participation Agreement
								<span class="small"><small><i class="fas fa-external-link-alt"></i></small></span></a>,
							<a href="https://socaislands.com/legal-stuff/#customer-code-of-conduct" target="_terms"
								class="text-reset">Code of Conduct
								<span class="small"><small><i class="fas fa-external-link-alt"></i></small></span></a>.
						</div>
						<div class="col-12 alert alert-danger" align="center"
							v-if="tripSpotsRemaining < totalTravellers && !isReservationFormBusy">
							Not enough spots available.
						</div>
						<button class="btn btn-lg btn-dark" :disabled="isReservationFormBusy || !isFormValid"
							@click="submitBookingForm" v-else>
							<div class="spinner-border" role="status" v-if="isReservationFormBusy">
								<span class="visually-hidden">Loading...</span>
							</div>
							<span v-else>Book
								{{
									totalTravellers > 1
									? `${totalTravellers} spots`
									: totalTravellers === 1
										? "1 spot"
										: "---"
								}}
							</span>
						</button>
						<div>&nbsp;</div>
					</div>
				</li>
			</ul>
		</div>
	</div>
	<div class="mb-5">&nbsp;</div>
	<div class="mb-5">&nbsp;</div>
</template>

<script>
import { ref } from "vue";
import { doc, onSnapshot } from "firebase/firestore";
import { db, collections } from "../../firebaseConfig";
import ChooseAccommodation from "./ChooseAccommodation.vue";
import ParadeCostume from "./ParadeCostume.vue";
import PaymentMethod from "../common/PaymentMethod.vue";
import PaymentSchedule from "./PaymentSchedule.vue";
import TransportType from "./TransportType.vue";
import TravellerDetails from "./TravellerDetails.vue";
export default {
	name: "booking-form",
	setup() {
		const customerFormValues = ref({});
		return { customerFormValues };
	},
	components: {
		ChooseAccommodation,
		TransportType,
		TravellerDetails,
		ParadeCostume,
		PaymentSchedule,
		PaymentMethod,
	},
	computed: {
		totalTravellers() {
			return this.$store.state.totalTravellers;
		},
		tripAvailability() {
			return this.$store.state.availability;
		},
		tripSpotsRemaining() {
			return Number(this.tripAvailability.quantity - this.tripAvailability.sold);
		},
		selectedHotel() {
			return this.$store.state.selectedHotel;
		},
		isReservationValid() {
			const {
				selectedEvent,
				selectedTransport,
				travellers,
				totalTravellers,
				selectedCostumes,
				selectedCostumesData,
				payFullAmount,
				selectedPaymentMethod,
			} = this.$store.state;

			if (Object.keys(selectedTransport).length < 1) {
				return false;
			}

			if (Object.keys(travellers).length < 1) {
				return false;
			}

			if (travellers.fname?.length < totalTravellers) {
				return false;
			}

			if (payFullAmount === null) {
				return false;
			}

			if (selectedPaymentMethod === null) {
				return false;
			}

			if (selectedEvent.type == "carnival") {
				if (selectedCostumes.length < totalTravellers) {
					return false;
				}
				for (let i = 0; i < selectedCostumes.length; i++) {
					if (selectedCostumes[i] != "no") {
						if (selectedCostumesData.length < 1) {
							return false;
						}

						if (Object.keys(selectedCostumesData).length < 1) {
							return false;
						}
					}
				}
			}
			return true;
		},
		isFormValid() {
			const { terms, covidTerms, privacy, thirdPartyTerms, insurance } = this.legal;

			if (!this.isReservationValid) {
				return false;
			}

			if (!terms) {
				return false;
			}
			if (!covidTerms) {
				return false;
			}
			if (!privacy) {
				return false;
			}
			if (!thirdPartyTerms) {
				return false;
			}
			if (!insurance) {
				return false;
			}

			return true;
		},
		isReservationFormBusy() {
			return this.$store.state.isReservationFormBusy;
		},
	},
	data() {
		return {
			legal: {
				terms: false,
				covidTerms: false,
				privacy: false,
				thirdPartyTerms: false,
				insurance: false,
			},
		};
	},
	methods: {
		submitBookingForm() {
			this.$refs.paymentMethodRef.submitBookingForm();
		},
		async getIsReservationValid() {
			return await this.$store.dispatch("getIsReservationValid");
		},
	},
	created() {
		const eventId = this.$route.params.event_id;
		const eventRef = doc(db, `${collections.EVENTS}/${eventId}`);
		onSnapshot(eventRef, (doc) => {
			this.$store.commit("setSelectedEvent", { id: doc.id, ...doc.data() });
		});
	},
	mounted() { },
};
</script>
