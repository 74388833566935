<template>
	<ul class="list-group">
		<li class="list-group-item active bg-dark border-dark">
			<h5 class="my-1"><i class="fas fa-bed"></i> Accommodation</h5>
		</li>
		<li class="list-group-item">
			<p class="mb-4">
				If you're in search of roommates, utilise our
				<a href="/crewfinder" target="_crewfinder">Crewfinder
					<span class="small"><small><i class="fas fa-external-link-alt"></i></small></span></a>
				tool to help connect you with fellow travelers who share similar interests and
				preferences.
			</p>
			<ul class="list-group">
				<li class="list-group-item">
					<div class="row">
						<div class="col-12 col-md-9 my-1 bold">Hotel and room type</div>
						<div class="col-12 col-md-3 my-1 bold d-none d-md-block">No. of persons</div>
					</div>
				</li>
				<template v-for="hotel in hotelOptions.filter((hotel) => hotel.quantity > hotel.sold)" :key="hotel.id">
					<!-- @TODO: v-show="totalTravellers < 1 ||  hotel.id === selectedHotel.id"-->
					<li class="list-group-item" v-show="totalTravellers < 1 || hotel.id === selectedHotel.id">
						<div class="row">
							<div class="col-12 col-md-9 my-1">
								<strong>{{ hotel.name }}</strong><br />
								<span v-if="hotel.id === 'no-accommodation'" class="small text-muted">
									Choose this option by entering the number of travellers in your group
									that do not require accommodation.
								</span>
								<span v-else class="small text-muted">{{ hotel.nights }} nights. Max. {{ hotel.guests }}
									persons.
									{{ hotel.notes }}</span>
							</div>
							<div class="col-12 col-md-3 my-1">
								<label class="small text-secondary d-md-none">No. of persons</label>
								<input type="hidden" v-model.number="hotelRooms[hotel.id]" />
								<select class="form-select" v-model.number="roomTravellers[hotel.id]"
									@change="selectedHotelChange(hotel.id)">
									<option value="0" disabled>-- Please choose --</option>
									<option value="0">Reset this form</option>
									<option v-for="index in hotel.guests" :value="index" :key="index">
										{{ index }} {{ index == 1 ? "person" : "persons" }}
									</option>
								</select>
								<button class="btn btn-sm btn-link text-danger text-decoration-none" @click="
									roomTravellers[hotel.id] = 0;
								selectedHotelChange(hotel.id);
								$store.dispatch('resetReservation');
								$emit('resetReservation');
								" v-if="roomTravellers[hotel.id] > 0">
									Start over <i class="bi bi-arrow-counterclockwise"></i>
								</button>
							</div>
						</div>
					</li>
				</template>
			</ul>
			<div>&nbsp;</div>
		</li>
	</ul>
</template>

<script>
export default {
	name: "choose-accommodation",
	components: {},
	emits: ["resetReservation"],
	computed: {
		totalTravellers() {
			const totalTravellers = this.selectedHotel.travellers || 0;
			return totalTravellers;
		},
		hotelOptions() {
			this.initHotelInfo();
			const hotelOptions = this.$store.state.hotelOptions?.sort((a, b) =>
				a.name > b.name ? 1 : a.name < b.name ? -1 : 0
			);
			return hotelOptions;
		},
		transportOptions() {
			return this.$store.state.transportOptions;
		},
	},
	data() {
		return {
			isBusy: false,
			activeHotel: "",
			toggleHotel: false,
			hotelRooms: [],
			selectedHotel: {},
			roomTravellers: [],
		};
	},
	methods: {
		selectedHotelChange(selectedHotelId) {
			this.selectedHotel = {
				id: selectedHotelId,
				name: this.hotelOptions.find((h) => h.id === selectedHotelId).name,
				price: this.hotelOptions.find((h) => h.id === selectedHotelId).price,
				notes:
					this.hotelOptions.find((h) => h.id === selectedHotelId)?.notes ||
					"I have my own accommodation.",
				rooms: this.hotelRooms[selectedHotelId],
				travellers: this.roomTravellers[selectedHotelId],
			};

			//reset other cost items
			if (
				this.hotelRooms[selectedHotelId] == "0" ||
				this.roomTravellers[selectedHotelId] == "0"
			) {
				this.$store.dispatch("resetReservation");
				this.$emit("resetReservation");
			} else {
				this.$store.commit("setSelectedHotel", this.selectedHotel);
			}

			if (
				this.hotelRooms[selectedHotelId] != "0" ||
				this.roomTravellers[selectedHotelId] != "0"
			) {
				this.toggleHotel = true;
			} else {
				this.toggleHotel = false;
			}
			if (this.selectedHotel.price < 1) {
				const privateTransport = this.transportOptions.find((option) =>
					option.name.includes("Private")
				);
				delete privateTransport.sold;
				delete privateTransport.quantity;
				this.$store.commit("setSelectedTransport", privateTransport);
			}
			if (selectedHotelId === "no-accommodation") {
				if (this.roomTravellers["no-accommodation"] == "0") {
					this.toggleHotel = false;
					this.$store.dispatch("resetReservation");
				} else {
					//set transport option to private since the customer is not staying
					//at a Soca Islands accommodation partner
					const privateTransport = this.transportOptions.find((option) =>
						option.name.includes("Private")
					);
					delete privateTransport.sold;
					delete privateTransport.quantity;
					this.$store.commit("setSelectedTransport", privateTransport);
				}
			}
			this.$store.commit("setTotalTravellers", this.totalTravellers || 0);
			this.$store.dispatch("getTripCost");
		},
		initHotelInfo() {
			if (Array.isArray(this.hotelOptions)) {
				this.$store.state.hotelOptions.forEach((hotel) => {
					this.hotelRooms[hotel.id] = 1;
					this.roomTravellers[hotel.id] = 0;
				});
			}
		},
	},
	mounted() { },
};
</script>
