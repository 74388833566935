<template>
	<div id="pageTop" class="sticky-top">
		<ul class="list-group">
			<li class="list-group-item active bg-dark border-dark">
				<h5 class="my-1"><i class="fas fa-clipboard-list"></i> Trip summary</h5>
			</li>
			<li class="list-group-item">
				<p v-if="tripSpotsRemaining > 0 && tripSpotsRemaining <= 10" class="small text-danger">
					Only {{ tripSpotsRemaining }}
					{{ tripSpotsRemaining === 1 ? `spot` : `spots` }} remaining.
				</p>
				<h6 class="mb-3">
					{{ tripInfo.name }} <br />
					<span class="small">
						{{ formatDate(tripAvailability.start_date) }} to
						{{ formatDate(tripAvailability.end_date) }}
					</span>
				</h6>
				<div class="row my-1">
					<div class="col-12">
						Travellers: {{ totalTravellers == 0 ? " -- " : totalTravellers }}
					</div>
				</div>
				<div class="row text-secondary" v-for="index in totalTravellers" :key="index">
					<div class="col">&nbsp;&nbsp; &bull; {{ getTravellerName(index - 1) }}</div>
					<div class="col-auto" align="right">
						{{ getPricePerTraveller(index - 1) }}
					</div>
				</div>
			</li>
			<li class="list-group-item">
				<div class="row">
					<div class="col-6 my-1"><span>Total trip cost:</span><br /></div>
					<div class="col-6 my-1" align="right">
						<span v-if="tripCost > 0 && totalTravellers > 0">
							{{ formatCurrency(tripCost) }}
						</span>
						<h3 v-else>- - -</h3>
					</div>
				</div>
				<div class="row">
					<div class="col-6 my-1">
						<span>Total trip deposit:</span>
					</div>
					<div class="col-6 my-1" align="right">
						<span v-if="tripDeposit > 0 && totalTravellers > 0">
							{{ formatCurrency(tripDeposit) }}
						</span>
						<h3 v-else>- - -</h3>
					</div>
				</div>
			</li>
			<li class="list-group-item" v-if="paymentAmount > 0 && totalTravellers > 0">
				<div class="row">
					<div class="col-6">
						<span>Paying now: </span>
					</div>
					<div class="col-6 h5" align="right">
						<span v-if="selectedPaymentMethod == 'credit_card'">
							{{ formatCurrency(stripePaymentAmount / 100) }}
							<span class="small text-muted"><br />
								<small style="font-weight: 400; font-size: 10pt">
									(includes
									{{
										formatCurrency(parseFloat(stripePaymentAmount / 100 - paymentAmount))
									}}
									processing fee)
								</small>
							</span>
						</span>
						<span v-else>
							{{ formatCurrency(paymentAmount) }}
						</span>
					</div>
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
import moment from "moment";
import { formatDate, formatCurrency } from "../../utils";
export default {
	name: "booking-price",
	components: {},
	computed: {
		totalTravellers() {
			return this.$store.state.totalTravellers;
		},
		paymentAmount() {
			return this.$store.state.paymentAmount;
		},
		stripePaymentAmount() {
			return this.$store.state.stripePaymentAmount;
		},
		selectedPaymentMethod() {
			return this.$store.state.selectedPaymentMethod;
		},
		selectedHotel() {
			return this.$store.state.selectedHotel;
		},
		selectedTransport() {
			return this.$store.state.selectedTransport;
		},
		selectedCostumes() {
			return this.$store.state.selectedCostumes;
		},
		selectedAddons() {
			return this.$store.state.selectedAddons;
		},
		tripInfo() {
			return this.$store.state.selectedEvent;
		},
		tripAvailability() {
			return this.$store.state.availability;
		},
		tripSpotsRemaining() {
			return Number(this.tripAvailability.quantity - this.tripAvailability.sold);
		},
		tripCost() {
			return this.$store.state.totalTripCost;
		},
		dateDiff() {
			const dateDiff = moment.unix(this.tripInfo.start_date).diff(moment(), "days");
			return dateDiff;
		},
		tripDeposit() {
			return this.$store.state.totalTripDeposit;
		},
		travellers() {
			return this.$store.state.travellers;
		},
	},
	data() {
		return {
			formatDate,
			formatCurrency,
			isBusy: false,
		};
	},
	methods: {
		getCostumePerPerson(index) {
			return parseFloat(this.selectedCostumes[index]?.price || 0);
		},
		getAddonsPerPerson(index) {
			const addonsList = this.selectedAddons.filter((addon) => addon.idx === index);
			let addonsPrice = 0;
			for (let i = 0; i < addonsList.length; i++) {
				addonsPrice += parseFloat(addonsList[i]?.price || 0);
			}
			return parseFloat(addonsPrice);
		},
		getTravellerName(index) {
			let travellerName = index === 0 ? `Lead Traveller` : `Traveller ${index + 1}`;
			return travellerName;
		},
		getPricePerTraveller(index) {
			let pricePerPerson = this.tripAvailability.start_price;
			pricePerPerson += parseFloat(this.selectedHotel.price / this.totalTravellers);
			pricePerPerson += parseFloat(this.selectedTransport.price || 0);
			pricePerPerson += this.getCostumePerPerson(index);
			pricePerPerson += this.getAddonsPerPerson(index);

			this.$store.commit("setPricePerTraveller", { index: index, price: pricePerPerson });

			return formatCurrency(pricePerPerson);
		},
	},
	mounted() { },
};
</script>
