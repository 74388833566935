<template>
	<div class="p-md-4 mb-5" id="pageTop">
		<h3 class="modal-title d-none d-lg-block mb-4" id="bookingFormModalLabel">
			Booking details
		</h3>
		<div class="d-flex col d-lg-none py-4 bg-white sticky-top">
			<div class="flex-grow-1 bold">
				<strong class="fs-5">Total cost:
					{{
						totalTravellers > 0 ? formatCurrency(totalTripCost) : "---"
					}}</strong>
				<br /><button class="btn btn-sm btn-dark" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom"
					aria-controls="offcanvasBottom">
					View cost per person
				</button>
			</div>
		</div>
		<NewBooking :key="bookingFormKey" />
	</div>

	<div class="offcanvas offcanvas-end" tabindex="-2" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
		<div class="offcanvas-header">
			<h5 class="offcanvas-title" id="offcanvasBottomLabel">&nbsp;</h5>
			<button type="button" class="btn-close btn-lg text-reset" data-bs-dismiss="offcanvas"
				aria-label="Close"></button>
		</div>
		<div class="offcanvas-body small">
			<BookingSummary />
		</div>
	</div>
</template>

<script>
import moment from "moment";
import { doc, getDoc } from "firebase/firestore";
import { formatDate, formatCurrency } from "../utils";
import { db, collections, subCollections } from "../firebaseConfig";
import NewBooking from "../components/book/NewBooking.vue";
import BookingSummary from "../components/book/BookingSummary.vue";
export default {
	name: "BookNow",
	components: { NewBooking, BookingSummary },
	computed: {
		totalTripCost() {
			return this.$store.state.totalTripCost;
		},
		totalTripDeposit() {
			return this.$store.state.totalTripDeposit;
		},
		selectedEvent() {
			return this.$store.state.selectedEvent;
		},
		totalTravellers() {
			return this.$store.state.totalTravellers;
		},
	},
	data() {
		return {
			formatDate,
			formatCurrency,
			isBusy: false,
			bookingFormKey: Date.now(),
			availability: [],
		};
	},
	methods: {
		btnContinueClick(availability) {
			const eventId = this.$route.params.event_id;
			this.bookingFormKey = Date.now();
			this.$store.dispatch("resetReservation");
			this.$store.commit("setAvailability", availability);
			this.$store.dispatch("getAvailabilityData", {
				availability: availability,
				eventId: eventId,
			});
		},
		dateDiff(startDate) {
			const dateDiff = moment.unix(startDate).diff(moment(), "days");
			return dateDiff;
		},
		getDeposit(option) {
			const { base_price, start_date } = option;
			let deposit = 0;
			if (this.dateDiff(start_date) > 90) {
				deposit = base_price * 0.15;
			} else if (this.dateDiff > 60 && this.dateDiff <= 90) {
				deposit = base_price * 0.5;
			} else if (this.dateDiff <= 60) {
				deposit = base_price;
			}

			return formatCurrency(deposit);
		},
		showRemainingQuantity(option) {
			const qtyAvailable = option.quantity - option.sold;
			if (option?.show_remaining_quantity) {
				return true;
			}
			if (qtyAvailable > 0 && qtyAvailable < 11) {
				return true;
			}
			return false;
		},
	},
	async created() {
		this.$store.commit("setPageTitle", "Book Now");
		const eventId = this.$route.params.event_id;
		const availabilityId = this.$route.params.travel_date_id;
		const docRef = doc(db, `${collections.EVENTS}/${eventId}/${subCollections.AVAILABILITY}`, `${availabilityId}`);
		const docSnap = await getDoc(docRef);
		const docData = { id: docSnap.id, ...docSnap.data() };
		this.$store.dispatch("resetReservation");
		this.$store.commit("setAvailability", docData);
		this.$store.dispatch("getAvailabilityData", {
			availability: docData,
			eventId: eventId,
		});
	},
};
</script>
<style>
.app-logo {
	width: 70px !important;
	height: auto;
}

.modal-header {
	border-bottom: 0 !important;
}
</style>
